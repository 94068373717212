import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react"; // Import JoditEditor
import "../../common.css";
import Sidebar from "./AdminSidebar";
import { useAuth } from "../../AuthContext";

function CreateNewTemplate({ setReportData }) {
  const navigate = useNavigate();

  // State declarations
  const [subHeading, setSubHeading] = useState("");
  const [dynamicFieldTypes, setDynamicFieldTypes] = useState([]);
  const [errors, setErrors] = useState({});
  const [draggedSectionId, setDraggedSectionId] = useState(null);
  const [draggedFieldIndex, setDraggedFieldIndex] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [textInput, setTextInput] = useState("");

  const { apipath } = useAuth();
  const token = localStorage.getItem("adminaccessToken");

  const [reportFields, setReportFields] = useState([
    {
      title: "Report Title: [Entity/Individual Name]",
      value: "",
      editable: false,
    },
    { title: "Date of Report: [Insert Date]", value: "", editable: false },
    { title: "Prepared By: [Name/Company Name]", value: "", editable: false },
  ]);

  const [sections, setSections] = useState([
    {
      id: "executive-summary",
      title: "Executive Summary",
      fields: [{ content: "" }],
    },
    {
      id: "subject-information",
      title: "Subject Information",
      fields: [
        {
          name: [""],
          address: "",
          contact: "",
          legalStructure: [""],
          registrationNumber: [""],
          keyPersons: [""],
        },
      ],
    },
    {
      id: "corporate-information",
      title: "Corporate Information",
      fields: [{ content: "" }],
    },
    {
      id: "financial-assessment",
      title: "Financial Assessment",
      fields: [{ content: "" }],
    },
    {
      id: "legal-compliance",
      title: "Legal and Regulatory Compliance",
      fields: [{ content: "" }],
    },
    {
      id: "reputation-analysis",
      title: "Reputation Analysis",
      fields: [{ content: "" }],
    },
    {
      id: "risk-assessment",
      title: "Risk Assessment",
      fields: [{ content: "" }],
    },
    {
      id: "recommendations",
      title: "Recommendations",
      fields: [{ content: "" }],
    },
    { id: "dynamic-fields", fields: [{}], editable: true },
  ]);

  // Submit template data to backend
  const submitTemplateData = async (templateData) => {
    try {
      const response = await fetch(`${apipath}/templates/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(templateData),
      });
  
      if (response.ok) {
        // Successfully saved the template
        navigate("/reporttemplates");
      } else {
        const errorData = await response.json();
        console.error("Error saving template:", errorData);
        setErrors(errorData); // Set errors for displaying them in the UI
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors({ general: "Failed to save template. Please try again." });
    }
  };  

  // Popup handling
  const handleSaveTemplate = () => {
    setPopupOpen(true);
  };

  // Handle popup submit
  const handlePopupSubmit = async () => {
    const templateData = {
      name: textInput, // Template name from the popup input
      template_structure: {
        reportFields,
        sections,
      }, // Collect report fields and sections
    };
  
    try {
      // Store data in localStorage
      localStorage.setItem("templateData", JSON.stringify(templateData));
  
      // Submit the data to the backend
      await submitTemplateData(templateData);

    } catch (error) {
      console.error("Error saving template:", error);
      // Optionally, show an error message to the user
    }
  };
  

  const handleSubHeadingChange = (e) => {
    setSubHeading(e.target.value);
  };

  // Dynamic fields management
  const addDynamicFieldType = () => {
    setDynamicFieldTypes([...dynamicFieldTypes, { id: Date.now(), name: "" }]);
  };

  // Report fields management
  const handleReportFieldChange = (index, value) => {
    const updatedFields = [...reportFields];
    updatedFields[index].value = value;
    setReportFields(updatedFields);
  };

  const handleReportFieldEditToggle = (index) => {
    const updatedFields = [...reportFields];
    if (updatedFields[index]) {
      updatedFields[index].editable = !updatedFields[index].editable;
      setReportFields(updatedFields);
    }
  };

  const removeReportField = (index) => {
    const updatedFields = reportFields.filter((_, i) => i !== index);
    setReportFields(updatedFields);
  };

  const addReportField = () => {
    const newField = { title: "New Field", value: "", editable: false };
    setReportFields([...reportFields, newField]);
  };

  // Change handling
  const handleChange = (sectionId, fieldIndex, field, value, inputIndex = null) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = [...section.fields];
        if (inputIndex !== null) {
          updatedFields[fieldIndex] = {
            ...updatedFields[fieldIndex],
            [field]: updatedFields[fieldIndex][field].map((v, i) =>
              i === inputIndex ? value : v
            ),
          };
        } else {
          updatedFields[fieldIndex] = {
            ...updatedFields[fieldIndex],
            [field]: value,
          };
        }
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const addField = (sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        // Reset to default field structure based on section
        let newField = {};
        if (sectionId === "subject-information") {
          newField = {
            name: [""], // Initialize with an empty name array
            address: "",
            contact: [""], // Initialize with an empty contact array
            legalStructure: [""],
            registrationNumber: [""],
            keyPersons: [""],
          };
        } else {
          newField = { content: "" };
        }
        return {
          ...section,
          fields: [...section.fields, newField],
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const removeField = (sectionId, fieldIndex) => {
    if (sectionId === "dynamic-fields") {
      setDynamicFieldTypes((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields.splice(fieldIndex, 1);
        return updatedFields;
      });
    } else {
      setSections((prevSections) => {
        return prevSections.map((section) => {
          if (section.id === sectionId) {
            const updatedFields = [...section.fields];
            updatedFields.splice(fieldIndex, 1);
            return { ...section, fields: updatedFields };
          }
          return section;
        });
      });
    }
  };

  // Drag-and-drop handlers for sections
  const handleDragStart = (sectionId) => {
    setDraggedSectionId(sectionId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (sectionId) => {
    const draggedIndex = sections.findIndex(
      (section) => section.id === draggedSectionId
    );
    const targetIndex = sections.findIndex(
      (section) => section.id === sectionId
    );
    const updatedSections = [...sections];
    const [draggedSection] = updatedSections.splice(draggedIndex, 1);
    updatedSections.splice(targetIndex, 0, draggedSection);
    setSections(updatedSections);
    setDraggedSectionId(null);
  };

  // Drag-and-drop handlers for report fields
  const handleFieldDragStart = (e, index) => {
    setDraggedFieldIndex(index);
  };

  const handleFieldDrop = (e, index) => {
    e.preventDefault();
    if (draggedFieldIndex !== null && draggedFieldIndex !== index) {
      const updatedFields = [...reportFields];
      const [draggedField] = updatedFields.splice(draggedFieldIndex, 1);
      updatedFields.splice(index, 0, draggedField);
      setReportFields(updatedFields);
      setDraggedFieldIndex(null);
    }
  };

  const renderInputs = (
    label,
    value,
    fieldKey,
    sectionId,
    fieldIndex,
    placeholderPrefix,
    editablePlaceholder // New parameter for editable placeholder
  ) => (
    <div className="input-group">
      <label>{label}</label>
      <input
        type="text"
        value={Array.isArray(value) ? value.join(", ") : value} // Join if it's an array
        onChange={(e) =>
          handleChange(sectionId, fieldIndex, fieldKey, e.target.value)
        }
        placeholder={`${placeholderPrefix} ${editablePlaceholder || label.toLowerCase()}`} // Use editable placeholder
      />
    </div>
  );

  return (
    <>
      <Sidebar />
      <div className="due-diligence-form">
        {/* Main Heading Input */}
        <div className="heading-container">
          <input
            type="text"
            value={subHeading}
            onChange={handleSubHeadingChange}
            placeholder="Report Title"
            className="main-heading input-centered"
          />
          {errors.subHeading && (
            <span className="error">{errors.subHeading}</span>
          )}
        </div>

        {/* Report Fields Section */}
        <div className="report-fields">
          <h2>Report Fields</h2>
          {reportFields.map((field, index) => (
            <div
              key={index}
              className="report-field-container"
              draggable
              onDragStart={(e) => handleFieldDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleFieldDrop(e, index)}
            >
              {field.editable ? (
                <>
                  <input
                    type="text"
                    value={field.title}
                    onChange={(e) => {
                      const updatedFields = [...reportFields];
                      updatedFields[index].title = e.target.value;
                      setReportFields(updatedFields);
                    }}
                    placeholder="Field Title"
                  />
                  <button
                    type="button"
                    onClick={() => handleReportFieldEditToggle(index)}
                    className="remove-button"
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <label>{field.title}</label>
                  <input
                    type="text"
                    value={field.value}
                    onChange={(e) =>
                      handleReportFieldChange(index, e.target.value)
                    }
                    placeholder={`Enter ${field.title.toLowerCase()}`}
                  />
                  <button
                    className="remove-button"
                    type="button"
                    onClick={() => handleReportFieldEditToggle(index)}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => removeReportField(index)}
                    className="remove-button"
                  >
                    Remove
                  </button>
                  {errors[`reportField-${index}`] && (
                    <span className="error">
                      {errors[`reportField-${index}`]}
                    </span>
                  )}
                </>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={addReportField}
            className="add-field-button"
          >
            Add Field
          </button>
        </div>

        {/* Sections Management */}
        <div className="sections-container">
          <h2>Sections</h2>
          {sections.map((section) => (
            <div
              key={section.id}
              className="section-container"
              draggable
              onDragStart={() => handleDragStart(section.id)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(section.id)}
            >
              {section.editable ? (
                <input
                  type="text"
                  value={section.title}
                  onChange={(e) =>
                    setSections((prevSections) =>
                      prevSections.map((s) =>
                        s.id === section.id
                          ? { ...s, title: e.target.value }
                          : s
                      )
                    )
                  }
                  className="section-title-input"
                  placeholder={`Enter title for section ${section.id}`}
                />
              ) : (
                <h3 onClick={() => setSections((prevSections) =>
                  prevSections.map((s) =>
                    s.id === section.id
                      ? { ...s, editable: true }
                      : s
                  )
                )}>{section.title}</h3>
              )}

              {section.id === "dynamic-fields" ? (
                <>
                  {dynamicFieldTypes.map((field, fieldIndex) => (
                    <div key={fieldIndex} className="field-container">
                      <h3>{field.name}</h3>{" "}
                      {/* Dynamic heading for each field */}
                      <JoditEditor
                        value={field.content}
                        onChange={(newContent) =>
                          handleChange(
                            section.id,
                            fieldIndex,
                            "content",
                            newContent
                          )
                        }
                      />
                      <div className="button-container">
                        <button
                          type="button"
                          onClick={() =>
                            removeField("dynamic-fields", fieldIndex)
                          }
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addDynamicFieldType}
                    className="add-field-button"
                  >
                    Add Dynamic Field
                  </button>
                </>
              ) : (
                section.fields.map((field, fieldIndex) => (
                  <div key={fieldIndex} className="field-container">
                    {section.id === "subject-information" ? (
                      <>
                        {field.name !== undefined &&
                          renderInputs(
                            "Name",
                            field.name,
                            "name",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Name" // Editable placeholder
                          )}
                        {field.address !== undefined &&
                          renderInputs(
                            "Address",
                            field.address,
                            "address",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Address" // Editable placeholder
                          )}
                        {field.contact !== undefined &&
                          renderInputs(
                            "Contact",
                            field.contact,
                            "contact",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Contact" // Editable placeholder
                          )}
                        {field.legalStructure !== undefined &&
                          renderInputs(
                            "Legal Structure",
                            field.legalStructure,
                            "legalStructure",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Legal Structure" // Editable placeholder
                          )}
                        {field.registrationNumber !== undefined &&
                          renderInputs(
                            "Registration Number",
                            field.registrationNumber,
                            "registrationNumber",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Registration Number" // Editable placeholder
                          )}
                        {field.keyPersons !== undefined &&
                          renderInputs(
                            "Key Persons",
                            field.keyPersons,
                            "keyPersons",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Key Persons" // Editable placeholder
                          )}
                      </>
                    ) : (
                      <JoditEditor
                        value={field.content}
                        onChange={(newContent) =>
                          handleChange(
                            section.id,
                            fieldIndex,
                            "content",
                            newContent
                          )
                        }
                        placeholder={`Enter content for ${section.title}`} // Example placeholder for JoditEditor
                      />
                    )}

                    <div className="button-container">
                      <button
                        className="remove-button"
                        type="button"
                        onClick={() => removeField(section.id, fieldIndex)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))
              )}

              {section.id !== "dynamic-fields" && (
                <button
                  type="button"
                  onClick={() => addField(section.id)}
                  className="add-field-button"
                >
                  Add Field
                </button>
              )}
            </div>
          ))}
        </div>

        {/* Save Template Button */}
        <div className="submit-container">
          <button
            type="submit"
            onClick={handleSaveTemplate}
            className="submit-button"
          >
            Save Template
          </button>
        </div>

        {/* Popup Component */}
        {isPopupOpen && (
          <div className="poops">
            <div className="pop-container">
              <h2 className="headingconatiner">Save Template</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePopupSubmit();
                }}
              >
                <label>
                  Enter template name:
                  <input
                    type="text"
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    required
                  />
                </label>
                <div className="pop-button-container">
                  <button type="submit" className="pop-submit-button">
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => setPopupOpen(false)}
                    className="pop-cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CreateNewTemplate;