import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import EmployeeSidebar from "./EmployeeSidebar";
import { useAuth } from '../../AuthContext';

const EmployeeDashboard = () => {
    const { apipath } = useAuth();
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [totalReports, setTotalReports] = useState(0);
    const [reports, setReports] = useState([]);

    const loggedInUserId = localStorage.getItem('employee_id');
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const employeeResponse = await fetch(`${apipath}/getallusers/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Check if the response is ok (status 200-299)
                if (!employeeResponse.ok) {
                    throw new Error(`Error: ${employeeResponse.status} ${employeeResponse.statusText}`);
                }

                // Parse the response body as JSON
                const users = await employeeResponse.json();

                setTotalEmployees(users.count);

                // setTotalReporttemplates(reporttemplateResponse.data.total_templates);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [apipath, token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const Response = await fetch(`${apipath}/projects/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!Response.ok) {
                    throw new Error(`Error: ${Response.status} ${Response.statusText}`);
                }

                // Parse the response body as JSON
                const projects = await Response.json();

                // Assuming the response contains an array of projects, we can count them
                const projectCount = projects.filter(project => project.assigned_to_display.includes(loggedInUserId)).length;
                setTotalProjects(projectCount);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [apipath, loggedInUserId, token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const Response = await fetch(`${apipath}/reports/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!Response.ok) {
                    throw new Error(`Error: ${Response.status} ${Response.statusText}`);
                }

                // Parse the response body as JSON
                const reports = await Response.json();

                // Assuming the response contains an array of projects, we can count them
                const reportCount = reports.length;
                setReports(reports)
                setTotalReports(reportCount);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [apipath, token]);

    // Helper functions to filter reports by their status
    const getOngoingReports = () => reports.filter(report => report.report_status === "ongoing");
    const getUnderVerificationReports = () => reports.filter(report => report.report_status === "under_review");
    const getClosedReports = () => reports.filter(report => report.report_status === "published");

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <Navbar />
            <EmployeeSidebar />
            <div className="home-main-content">

                <div className="container ">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="dash-column"
                                style={{ cursor: "pointer" }}>
                                <div>
                                    <h4>{totalEmployees}</h4>
                                    <h6>Total Staff</h6>
                                </div>
                                <div>
                                    <img src="images/staff.png" width={40} height={40} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="dash-column">
                                <div>
                                    <h4>{totalProjects || 0}</h4>
                                    <h6>Total Projects</h6>
                                </div>
                                <div>
                                    <img src="images/project.png" width={40} height={40} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="dash-column">
                                <div>
                                    <h4>{totalReports || 0}</h4>
                                    <h6>Total Reports</h6>
                                </div>
                                <div>
                                    <img src="images/docs.png" width={40} height={40} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-2">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="kanban-column">
                                <div className="kanban-heading">
                                    <div className="d-flex">
                                        <img className="me-2" src="images/report.png" width={20} height={20} alt="" />
                                        <h6>Report Under Edit Mode</h6>
                                    </div>
                                    <div>
                                        <h4>{getOngoingReports().length}</h4>
                                    </div>
                                </div>
                                {getOngoingReports().map((report) => (
                                    <div className="kanban-content" key={report.id}>
                                        <div className="report-box">
                                            <div className="d-flex">
                                                <img className="me-2" src="images/ongoing.png" width={20} height={20} alt="" />
                                                <h5>{report.name} <br /> <span>{formatDate(report.created_at)}</span></h5>
                                            </div>
                                            <div className="dropdown">
                                                <i className="bi bi-three-dots-vertical" data-bs-toggle="dropdown"></i>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button className="dropdown-item">
                                                            View Report
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="kanban-column">
                                <div className="kanban-heading">
                                    <div className="d-flex">
                                        <img className="me-2" src="images/report.png" width={20} height={20} alt="" />
                                        <h6>Report Under Review Mode</h6>
                                    </div>
                                    <div>
                                        <h4>{getUnderVerificationReports().length}</h4>
                                    </div>
                                </div>
                                {getUnderVerificationReports().map((report) => (
                                    <div className="kanban-content" key={report.id}>
                                        <div className="report-box">
                                            <div className="d-flex">
                                                <img className="me-2" src="images/pending.png" width={20} height={20} alt="" />
                                                <h5>{report.name} <br /> <span>{formatDate(report.created_at)}</span></h5>
                                            </div>
                                            <div className="dropdown">
                                                <i className="bi bi-three-dots-vertical" data-bs-toggle="dropdown"></i>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button className="dropdown-item">
                                                            View Report
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="kanban-column">
                                <div className="kanban-heading">
                                    <div className="d-flex">
                                        <img className="me-2" src="images/report.png" width={20} height={20} alt="" />
                                        <h6>Published Report</h6>
                                    </div>
                                    <div>
                                        <h4>{getClosedReports().length}</h4>
                                    </div>
                                </div>
                                {getClosedReports().map((report) => (
                                    <div className="kanban-content" key={report.id}>
                                        <div className="report-box">
                                            <div className="d-flex">
                                                <img className="me-2" src="images/closed.png" width={20} height={20} alt="" />
                                                <h5>{report.name} <br /> <span>{formatDate(report.created_at)}</span></h5>
                                            </div>
                                            <div className="dropdown">
                                                <i className="bi bi-three-dots-vertical" data-bs-toggle="dropdown"></i>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button className="dropdown-item">
                                                            View Report
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default EmployeeDashboard;
