import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import "../../src/common.css";

const ReportDisplay = () => {
  const { state } = useLocation();
  const reportDetails = state ? state.reportDetails : null;

  const [isVertical, setIsVertical] = useState(false);
  const [viewFormat, setViewFormat] = useState("detailed");
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Report',
  });

  if (!reportDetails) {
    return <h2>No report data available.</h2>;
  }

  const VerticalReport = () => (
    <div ref={componentRef} className="report-info vertical-report">
      <table className="vertical-report-table">
        <thead>
          <tr>
            <th>NAME</th>
            <th>STATUS</th>
            <th>ADDRESS</th>
            <th>MANAGEMENT</th>
            <th>SHAREHOLDERS</th>
            <th>ACTIVITIES</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="company-value">Baobab Fertilizer Africa (C129735)</td>
            <td className="company-value">20.04.2015 – Active</td>
            <td className="company-value">
              33 Edith Cavell Street, C/o IQ EQ Corporate Services (Mauritius) Ltd, Port Louis, Mauritius
            </td>
            <td className="company-value">
              <div>Manar Ba – Director (since 16.10.2019)</div>
              <div>Sevin Chendriah – Director (since 15.02.2016)</div>
              <div>Claude Fizaine – Director (since 12.09.2022)</div>
              <div>Mark Gossel – Director (since 12.09.2022)</div>
              <div>Amit Gupta – Director (since 16.10.2019)</div>
              <div>Sharmanand Jhurreea – Director (since 04.11.2019)</div>
            </td>
            <td className="company-value">N/A</td>
            <td className="company-value">Fertilizer production</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
  
  const HorizontalReport = () => (
    <div ref={componentRef} className="report-info horizontal-report">
      <div className="company-row">
        <div className="company-label">Report Title</div>
        <div className="company-value">{reportDetails.title}</div>
      </div>
      <div className="company-row">
        <div className="company-label">Report Date</div>
        <div className="company-value">{reportDetails.date}</div>
      </div>
      <div className="company-row">
        <div className="company-label">Prepared By</div>
        <div className="company-value">{reportDetails.preparedBy}</div>
      </div>
      {reportDetails.sections.map((section, index) => (
        <React.Fragment key={index}>
          <div className="company-row">
            <div className="company-label">{section.title}</div>
            <div className="company-value">
              {section.fields.map((field, fieldIndex) => (
                <div key={fieldIndex}>
                  {section.id === "executive-summary" ? (
                    <div>
                      <strong>Purpose:</strong> {field.purpose}<br />
                      <strong>Summary:</strong> {field.summary}
                    </div>
                  ) : (
                    <div>{field.content}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className="report-container">
      <h1>Report Display</h1>
      <button className="show-pdf-button" onClick={handlePrint}>Print Report</button>
      {isVertical ? <VerticalReport /> : <HorizontalReport />}
      <button onClick={() => setIsVertical(!isVertical)}>
        Toggle to {isVertical ? 'Horizontal' : 'Vertical'}
      </button>
    </div>
  );
};

export default ReportDisplay;
