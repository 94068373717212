import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Sidebar from "../Component/Admin/AdminSidebar";
import Navbar from "../Component/Navbar";
import "../../src/common.css";

const Reports = ({ reportData }) => {
 
  
  const reportRef = useRef();
  const [viewFormat, setViewFormat] = useState("detailed"); // State for view format
  const [reportFields, setReportFields] = useState(reportData?.fields || []); // Dynamic fields from report data
  const { title, subHeading, fields, sections } = reportData || {}; // Destructure reportData

  if (!reportData) {
    return <div>No report data available.</div>;
  }

  const isStringEmpty = (str) => !str || str.trim() === ""; // Check if string is empty or only contains whitespace

  // Utility function to check if a field is empty
  const isFieldEmpty = (field) => {
    return Object.values(field).every((value) => !value); // Check if all values are empty
  };

  // Utility function to check if a section has any non-empty fields
  const hasNonEmptyFields = (section) => {
    return section.fields.some((field) => !isFieldEmpty(field)); // Check if at least one field has content
  };

  
  // Detailed Table Format
  const DetailedTable = () => (

    <div ref={reportRef} className="company-info">
      {/* Render Report Title if not empty */}
      {!isStringEmpty(reportData.title) && (
        <div className="company-row">
          <div className="company-label">Report Title</div>
          <div className="company-value">{reportData.title}</div>
        </div>
      )}

      {/* Render Report Date if not empty */}
      {!isStringEmpty(reportData.date) && (
        <div className="company-row">
          <div className="company-label">Report Date</div>
          <div className="company-value">{reportData.date}</div>
        </div>
      )}

      {/* Render Prepared By if not empty */}
      {!isStringEmpty(reportData.preparedBy) && (
        <div className="company-row">
          <div className="company-label">Prepared By</div>
          <div className="company-value">{reportData.preparedBy}</div>
        </div>
      )}

      {/* Render dynamic report fields (only if field has a value) */}
      {reportFields.length > 0 &&
        reportFields.map((field, index) => (
          !isStringEmpty(field.value) && (
            <div className="company-row" key={index}>
              <div className="company-label">{field.title}</div>
              <div className="company-value">{field.value}</div>
            </div>
          )
        ))}
  
      {/* Render sections dynamically and additional section rendering */}
      {reportData.sections
        .filter((section) => hasNonEmptyFields(section)) // Filter out sections without any fields filled
        .map((section, index) => (
          <div className="company-row" key={index}>
            <div className="company-label">{section.title}</div>
            <div className="company-value">
              {section.fields
                .filter((field) => !isFieldEmpty(field)) // Filter out empty fields within the section
                .map((field, fieldIndex) => (
                  <div key={fieldIndex}>
                    {field.content ? (
                      <div>{field.content}</div>
                    ) : (
                      <div>
                        {field.name && (
                          <div>
                            <strong>Name:</strong> {field.name}
                            <br />
                          </div>
                        )}
                        {field.address && (
                          <div>
                            <strong>Address:</strong> {field.address}
                            <br />
                          </div>
                        )}
                        {field.contact && (
                          <div>
                            <strong>Contact:</strong> {field.contact}
                            <br />
                          </div>
                        )}
                        {field.legalStructure && (
                          <div>
                            <strong>Legal Structure:</strong> {field.legalStructure}
                            <br />
                          </div>
                        )}
                        {field.registrationNumber && (
                          <div>
                            <strong>Registration Number:</strong> {field.registrationNumber}
                            <br />
                          </div>
                        )}
                        {field.keyPersons && (
                          <div>
                            <strong>Key Persons:</strong> {field.keyPersons}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );

  // Summary Table Format
  const SummaryTable = () => (
      <div className="vertical-summary-table">
        {/* Render Report Title if not empty */}
        {!isStringEmpty(reportData.title) && (
          <div className="company-row-summary header-row">
            <div className="vertical-summary-label">Report Title</div>
            <div className="vertical-summary-value">{reportData.title}</div>
          </div>
        )}
    
        {/* Render Report Date if not empty */}
        {!isStringEmpty(reportData.date) && (
          <div className="vertical-summary-row">
            <div className="vertical-summary-label">Report Date</div>
            <div className="vertical-summary-value">{reportData.date}</div>
          </div>
        )}
    
        {/* Render Prepared By if not empty */}
        {!isStringEmpty(reportData.preparedBy) && (
          <div className="vertical-summary-row">
            <div className="vertical-summary-label">Prepared By</div>
            <div className="vertical-summary-value">{reportData.preparedBy}</div>
          </div>
        )}
    
        {/* Render dynamic report fields (only if field has a value) */}
        {reportFields.length > 0 &&
          reportFields.map((field, index) => (
            !isStringEmpty(field.value) && (
              <div className="vertical-summary-row" key={index}>
                <div className="vertical-summary-label">{field.title}</div>
                <div className="vertical-summary-value">{field.value}</div>
              </div>
            )
          ))}
    
        {/* Render sections dynamically */}
        {reportData.sections
          .filter((section) => hasNonEmptyFields(section)) // Filter out sections without any fields filled
          .map((section, index) => (
            <div className="vertical-summary-row" key={index}>
              <div className="vertical-summary-label">{section.title}</div>
              <div className="vertical-summary-value">
                {section.fields
                  .filter((field) => !isFieldEmpty(field)) // Filter out empty fields within the section
                  .map((field, fieldIndex) => (
                    <div key={fieldIndex} className="vertical-summary-field">
                      {field.content ? (
                        <div>{field.content}</div>
                      ) : (
                        <div>
                          {field.name && (
                            <div>
                              <strong>Name:</strong> {field.name}
                              <br />
                            </div>
                          )}
                          {field.address && (
                            <div>
                              <strong>Address:</strong> {field.address}
                              <br />
                            </div>
                          )}
                          {field.contact && (
                            <div>
                              <strong>Contact:</strong> {field.contact}
                              <br />
                            </div>
                          )}
                          {field.legalStructure && (
                            <div>
                              <strong>Legal Structure:</strong> {field.legalStructure}
                              <br />
                            </div>
                          )}
                          {field.registrationNumber && (
                            <div>
                              <strong>Registration Number:</strong> {field.registrationNumber}
                              <br />
                            </div>
                          )}
                          {field.keyPersons && (
                            <div>
                              <strong>Key Persons:</strong> {field.keyPersons}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    );
 
    const PDFTable = () => (
      <div className="summary-table-container">
        {/* Render Report Title if not empty */}
        {!isStringEmpty(reportData.title) && (
          <div className="summary-table-row">
            <div className="summary-table-label">Report Title</div>
            <div className="summary-table-value">{reportData.title}</div>
          </div>
        )}
    
        {/* Render Report Date if not empty */}
        {!isStringEmpty(reportData.date) && (
          <div className="summary-table-row">
            <div className="summary-table-label">Report Date</div>
            <div className="summary-table-value">{reportData.date}</div>
          </div>
        )}
    
        {/* Render Prepared By if not empty */}
        {!isStringEmpty(reportData.preparedBy) && (
          <div className="summary-table-row">
            <div className="summary-table-label">Prepared By</div>
            <div className="summary-table-value">{reportData.preparedBy}</div>
          </div>
        )}
    
        {/* Render dynamic report fields (only if field has a value) */}
        {reportFields.length > 0 &&
          reportFields.map((field, index) =>
            !isStringEmpty(field.value) && (
              <div className="summary-table-row" key={index}>
                <div className="summary-table-label">{field.title}</div>
                <div className="summary-table-value">{field.value}</div>
              </div>
            )
          )}
    
        {/* Render sections dynamically */}
        {reportData.sections
          .filter((section) => hasNonEmptyFields(section)) // Filter out sections without any fields filled
          .map((section, index) => (
            <div className="summary-table-row" key={index}>
              <div className="summary-table-label">{section.title}</div>
              <div className="summary-table-value">
                {section.fields
                  .filter((field) => !isFieldEmpty(field)) // Filter out empty fields within the section
                  .map((field, fieldIndex) => (
                    <div key={fieldIndex} className="summary-table-field">
                      {field.content ? (
                        <div>{field.content}</div>
                      ) : (
                        <div>
                          {field.name && (
                            <div>
                              <strong>Name:</strong> {field.name}
                              <br />
                            </div>
                          )}
                          {field.address && (
                            <div>
                              <strong>Address:</strong> {field.address}
                              <br />
                            </div>
                          )}
                          {field.contact && (
                            <div>
                              <strong>Contact:</strong> {field.contact}
                              <br />
                            </div>
                          )}
                          {field.legalStructure && (
                            <div>
                              <strong>Legal Structure:</strong> {field.legalStructure}
                              <br />
                            </div>
                          )}
                          {field.registrationNumber && (
                            <div>
                              <strong>Registration Number:</strong> {field.registrationNumber}
                              <br />
                            </div>
                          )}
                          {field.keyPersons && (
                            <div>
                              <strong>Key Persons:</strong> {field.keyPersons}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    );
    
return (
   
  <>
    <Navbar />
    <Sidebar />


  <div className="reportdatafinaldisplay">
  {/* View format toggle buttons */}
  {/* Image first, then text in a row */}
  <div className="reportsdisplaymain">
    <img
     src={`${process.env.PUBLIC_URL}/images/logo.png`}
      alt="Image description"
      className="reports-image"
    />
    <div className="reports-title">
      ATHENA INTELLIGENCE
    </div>
  </div>

  {/* Title and Subheading */}
  <h1 className="reports-main-title">{title}</h1>
  <h4 className="reports-subheading">{subHeading}</h4>

  <div className="reports-button-container">
    <button className="bottom" onClick={() => setViewFormat("detailed")}>
      Detailed View
    </button>
    <button className="bottom" onClick={() => setViewFormat("summary")}>
      Summary View
    </button>
    <button className="bottom" onClick={() => setViewFormat("pdf")}>
      PDF View
    </button>
  </div>

  {/* Render based on selected format */}
  {viewFormat === "detailed" ? <DetailedTable /> : 
   viewFormat === "summary" ? <SummaryTable /> : 
   <PDFTable />}

  {/* Footer section */}
  <div className="reports-footer">
    <h3>Strictly Private & Confidential</h3>
  </div>
</div>
</>
);
};

export default Reports;
