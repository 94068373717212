// // services/reporttemplateService.js
// import axios from 'axios';

// const generateReport = async (data) => {
//   const response = await axios.post('http://localhost:5000/api/reporttemplate/generate-report', data, {
//     responseType: 'blob',
//   });
//   return response.data;
// };

// // export default generateReport;
// const updateReportTemplate = async (templateId, data) => {
//   const response = await axios.put(`http://localhost:5000/api/reporttemplate/update/${templateId}`, data);
//   return response.data;
// };

// const getReportTemplate = async (templateId) => {
//   const response = await axios.get(`http://localhost:5000/api/reporttemplate/get/${templateId}`);
//   return response.data;
// };

// export { generateReport, updateReportTemplate, getReportTemplate };

import axios from 'axios';

export const generateReport = async (data) => {
  const response = await axios.post('http://localhost:5000/api/reporttemplate/generate-report', data, { responseType: 'blob' });
  return response.data;
};

export const saveTemplate = async (data) => {
  const response = await axios.post('http://localhost:5000/api/reporttemplate/save-template', data);
  return response.data;
};

export const updateTemplate = async (templateId, data) => {
  const response = await axios.put(`http://localhost:5000/api/reporttemplate/update-template/${templateId}`, data);
  return response.data;
};
// export const getTemplate = async (templateId) => {
//   const response = await axios.get(`http://localhost:5000/api/reporttemplate/get/${templateId}`);
//   return response.data;
// };
export const getTemplate = async (templateId) => {
  const response = await axios.get(`http://localhost:5000/api/reporttemplate/get/${templateId}`);
  return response.data;
};
