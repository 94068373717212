import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./Component/Login";
import Dashboard from "./Component/Admin/AdminDashboard";
import AdminDocuments from "./Component/Admin/AdminDocuments";
import EmployeeDocuments from "./Component/Employee/EmployeeDocuments";
// import Reports from "./Component/Reportstemplates";
import EmployeeManagement from "./Component/Admin/EmployeeManagement";
import AdminProject from "./Component/Admin/AdminProject";
import ProjectDetails from "./Component/Admin/ProjectDetails";
import Organisation from "./Component/Admin/Organisation";
import Reportstemplate from "./Component/Admin/Reportstemplates";
import AdminReports from "./Component/Admin/AdminReports";
import CreateTemplate from "./Component/Admin/CreateTemplate";
import CreateAdminReport from "./Component/Admin/CreateAdminReport";
import EditAdminReport from "./Component/Admin/EditAdminReportbyId";
import EditReportTemplateByID from "./Component/Admin/EditReportTemplateByID";
import DownloadReportTemplate from "./Component/DownloadReportTemplate";
import CreateUserReport from "./Component/Employee/CreateUserReport";
import EmployeeProject from "./Component/Employee/EmployeeProject";
import EditUserReport from "./Component/Employee/EditUserReportbyId";
import EmployeeDashboard from "./Component/Employee/EmployeeDashboard";
import EmployeeReports from "./Component/Employee/EmployeeReports";
// import NewTemplate from "./Component/NewTemplate"; // created new
import Reports from "./Component/Reports"; //create new
import ReportDisplay from "./Component/ReportDisplay";
import Creatingg from "./Component/Creatingg";


function App() {
  const [reportData, setReportData] = useState(null); // State for report data

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/employee-dashboard" element={<EmployeeDashboard />} />
          <Route exact path="/admin-document" element={<AdminDocuments />} /> {/* can be used for achieve flow */}
          <Route exact path="/employee-document" element={<EmployeeDocuments />} />
          <Route exact path="/reporttemplates" element={<Reportstemplate />} />

          <Route exact path="/employees" element={<EmployeeManagement />} />
          <Route exact path="/admin-project" element={<AdminProject />} />
          <Route exact path="/project-detail/:id" element={<ProjectDetails />} />
          <Route exact path="/employee-project" element={<EmployeeProject/>} />
          <Route exact path="/organisation-list" element={<Organisation />} />
          <Route exact path="/download-report-template" element={<DownloadReportTemplate />} /> {/* Add the new route */}
          <Route exact path="/edit-report-template/:id" element={<EditReportTemplateByID />} /> {/* Edit with UI */}
          <Route exact path="/create-admin-report/:id" element={<CreateAdminReport />} />
          <Route exact path="/edit-admin-report/:id" element={<EditAdminReport />} />
          <Route exact path="/create-user-report/:id" element={<CreateUserReport />} />
          <Route exact path="/edit-user-report/:id" element={<EditUserReport />} />
          <Route exact path="/admin-reports/" element={<AdminReports />} />
          <Route exact path="/employee-reports" element={<EmployeeReports />} /> {/* Add the new route */}
          {/* <Route exact path="/reporttemplates" element={<NewTemplate  />} /> Add the new route */}
          <Route path="/CreateNewTemplate" element={<CreateTemplate setReportData={setReportData} />} />
          <Route path="/reports" element={<Reports reportData={reportData} />} /> {/* not needed */}
          <Route path="/report-display" element={<ReportDisplay />} /> {/* not needed */}
          <Route path="/creatingg" element={<Creatingg />} /> {/* not needed */}


        </Routes>
      </Router>
    </div>
  );
}

export default App;