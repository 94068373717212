import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from '../../AuthContext';

const EmployeeSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  const token = localStorage.getItem('accessToken');

  const { logout } = useAuth();
  if (!token) {
    logout();
    window.location.href = "/";
  }

  return (
    <>
      <header>
        <div className="toggle" onClick={toggleSidebar}>
          <i className={isSidebarOpen ? "bi bi-x" : "bi bi-list"}></i>
        </div>
      </header>
      <section className={`dapp-side-bar-nav ${isSidebarOpen ? 'open' : ''}`}>
        <ul className="pt-4">
          {/* <div className="nav-menu d-flex align-items-center mx-3 mb-4">
            <Link to="/"> 
              <img src="images/logo.png" alt="Logo" />  
            </Link>
          </div> */}
          <div className='hr-class'>
          <hr className="colored-hr" />
          </div>
          <small>Main</small>
          <li className={isActiveLink("/employee-dashboard") ? "active" : ""}>
            <Link to="/employee-dashboard">
              <span className="icon"><i className="bi bi-columns-gap"></i></span>
              <span className="title">Dashboard</span>
            </Link>
          </li>
          <li className={isActiveLink("") ? "active" : ""}>
            <Link to="/employee-project">
              <span className="icon"><i class="bi bi-kanban"></i></span>
              <span className="title">My Projects</span>
            </Link>
          </li>
          {/* <li className={isActiveLink("/employee-document") ? "active" : ""}>
            <Link to="/employee-document">
              <span className="icon"><i class="bi bi-file-earmark-medical"></i></span>
              <span className="title">Document</span>
            </Link>
          </li> */}
          <li className={isActiveLink("/employee-reports") ? "active" : ""}>
            <Link to="/employee-reports">
              <span className="icon"><i class="bi bi-file-earmark-medical"></i></span>
              <span className="title">My Reports</span>
            </Link>
          </li>
          {/* <li className={isActiveLink("/employeereporttemplates") ? "active" : ""}>
            <Link to="/employeereporttemplates">
              <span className="icon"><i class="bi bi-file-earmark-medical"></i></span>
              <span className="title">My Reports </span>
            </Link>
          </li> */}
          <div className='hr-class'>
          <hr className="colored-hr" />
          </div>
          <li>
            <Link to="">
              <span className="icon"><i class="bi bi-question-circle"></i></span>
              <span className="title">Help from Admin</span>
            </Link>
          </li>
          <li>
            <Link>
              <span className="icon"><i class="bi bi-door-closed"></i></span>
              <span className="title" onClick={logout}>Logout</span>
            </Link>
          </li>
        </ul>
      </section>

    </>
  );
};

export default EmployeeSidebar;
