import React from "react";
import Sidebar from "./EmployeeSidebar";
import Navbar from "../Navbar";
const Documents = () => {


    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="home-main-content">

                <div className="container pt-4">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="add-btn">
                                <button><i class="bi bi-folder-plus me-2"></i>Add New</button>
                            </div>
                            <hr />
                            <div class="nav vertical-tab flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"> <i class="bi bi-folder2-open"></i> Company Document </button>
                                <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i class="bi bi-folder2-open"></i> Identity Document </button>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i class="bi bi-folder2-open"></i> Company Document </button>
                                <button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i class="bi bi-folder2-open"></i> Company Document </button>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="document-box">
                                                <div className="doc-head">
                                                    <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                    <h5>GST Document</h5>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div className="doc-info">
                                                    <img src="images/folder.png" width={95} height={95} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    );
};

export default Documents;
