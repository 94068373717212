import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import Sidebar from "./AdminSidebar";
import $ from "jquery";
import "datatables.net";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar";
import { Modal } from "react-bootstrap";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import { useAuth } from '../../AuthContext';
import Select from 'react-select';

const Projects = () => {
    const dataTableRef = useRef();
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [organisations, setOrganisations] = useState([]); // Fetch organisation list
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const { apipath } = useAuth();
    const [projectData, setProjectData] = useState({
        associated_organisation: "",
        project_name: "",
        report_category: "",
        assigned_to: []
    });

    const token = localStorage.getItem('adminaccessToken');

    useEffect(() => {
        // Fetch Projects
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${apipath}/projects/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();

                // Fetch user names for created_by and updated_by
                const projectsWithUserDetails = await Promise.all(data.map(async (project) => {
                    const assignedBy = await fetchUserById(project.assigned_by);
                    return {
                        ...project,
                        assigned_by_name: assignedBy?.name || 'Unknown',
                    };
                }));

                setProjects(projectsWithUserDetails);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        // Fetch user details by ID
        const fetchUserById = async (userId) => {
            try {
                const response = await fetch(`${apipath}/getuserbymainId/${userId}/`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    return await response.json();
                }
            } catch {
                return null;
            }
        };

        // Fetch Employees
        const fetchEmployees = async () => {
            try {
                const response = await fetch(`${apipath}/getallusers/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                const filteredEmployees = data.employees.filter(emp => emp.role === "employee");
                setEmployees(filteredEmployees);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };

        // Fetch Organisations
        const fetchOrganisations = async () => {
            try {
                const response = await fetch(`${apipath}/organisation/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                setOrganisations(data);
            } catch (error) {
                console.error("Error fetching organisations:", error);
            }
        };

        fetchProjects();
        fetchEmployees();
        fetchOrganisations();
    }, [apipath, token]);

    const handleShowEdit = (project) => {
        setCurrentProject(project);
        setProjectData({
            // associated_organisation: project.organisation_name,
            associated_organisation: project.organisation_name.id,
            project_name: project.project_name,
            report_category: project.report_category,
            assigned_to: project.assigned_to_display || [] // Prefill with assigned_to_display
        });
        setShowEdit(true);
    };

    const handleCloseEdit = () => {
        setShowEdit(false);
        setCurrentProject(null);
    };

    const handleShowDelete = (project) => {
        setCurrentProject(project);
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setCurrentProject(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apipath}/projects/`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    associated_organisation: projectData.associated_organisation, // Changed from organisation_name
                    project_name: projectData.project_name,
                    report_category: projectData.report_category,
                    assigned_to: projectData.assigned_to.map(Number)
                })
            });
            if (!response.ok) {
                const errorData = await response.json();
                iziToast.error({
                    title: 'Error',
                    message: errorData.message || 'Error occurred while creating project',
                    position: 'topCenter',
                });
                return;
            }
            iziToast.success({
                title: 'Success',
                message: 'Project added successfully',
                position: 'topCenter',
            });

            const updatedResponse = await fetch(`${apipath}/projects/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedData = await updatedResponse.json();
            setProjects(updatedData);

            const registerModal = new window.bootstrap.Modal(document.getElementById('registerModal'));
            registerModal.hide();
            setProjectData({
                associated_organisation: "",
                project_name: "",
                report_category: "",
                assigned_to: []
            });
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while creating project.',
                position: 'topCenter',
            });
            console.error("Error creating project:", error);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apipath}/project/${currentProject.id}/`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    associated_organisation: projectData.associated_organisation, // Changed from organisation_name
                    project_name: projectData.project_name,
                    report_category: projectData.report_category,
                    assigned_to: projectData.assigned_to.map(Number)
                })
            });
            if (!response.ok) {
                const errorData = await response.json();
                iziToast.error({
                    title: 'Error',
                    message: errorData.message || 'Error occurred while updating project',
                    position: 'topCenter',
                });
                return;
            }
            iziToast.success({
                title: 'Success',
                message: 'Project updated successfully',
                position: 'topCenter',
            });

            const updatedResponse = await fetch(`${apipath}/projects/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedData = await updatedResponse.json();
            setProjects(updatedData);

            setShowEdit(false);
            setCurrentProject(null);
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while updating project.',
                position: 'topCenter',
            });
            console.error("Error updating project:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`${apipath}/project/${currentProject.id}/`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                iziToast.error({
                    title: 'Error',
                    message: errorData.message || 'Error occurred while deleting project',
                    position: 'topCenter',
                });
                return;
            }
            iziToast.success({
                title: 'Success',
                message: 'Project deleted successfully',
                position: 'topCenter',
            });

            const updatedResponse = await fetch(`${apipath}/projects/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedData = await updatedResponse.json();
            setProjects(updatedData);

            setShowDelete(false);
            setCurrentProject(null);
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while deleting project.',
                position: 'topCenter',
            });
            console.error("Error deleting project:", error);
        }
    };

    useEffect(() => {
        if (projects.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [projects]);

    return (
        <>
            <Sidebar />
            <Navbar />
            <div className="home-main-content">
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Project Management</h1>
                    </div>
                    <div className="container-fluid">
                        <div className="row foot-tablerow">
                            <div className="col-lg-12 maintable-column ">
                                <div className="container-fluid mt-4 overflow-auto">
                                    <div className="addemployee">
                                        <button className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#registerModal">
                                            <i className="fa-solid fa-user-plus"></i> Add Project
                                        </button>
                                    </div>
                                    <table id="datatable" className="table" ref={dataTableRef}>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Project Name</th>
                                                <th>Report Category</th>
                                                <th>Organisation Name</th>
                                                <th>Created By</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projects.map((project, index) => (
                                                <tr key={project.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{project.project_name}</td>
                                                    <td>{project.report_category}</td>
                                                    <td>{project.organisation_name.organisation_name}</td>
                                                    <td>{project.assigned_by_name}</td>
                                                    <td>
                                                        <i className="fa-regular fa-pen-to-square me-1" onClick={() => handleShowEdit(project)}></i>
                                                        <Link to={`/project-detail/${project.id}`}>
                                                            <i class="fa-regular fa-eye"></i>
                                                        </Link>&nbsp;
                                                        <i className="fa-solid fa-trash" onClick={() => handleShowDelete(project)}></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Project Modal */}
                    <div className="modal fade" id="registerModal" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="registerModalLabel">Add Project</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Organisation Name</label>
                                            <Select
                                                name="associated_organisation"
                                                options={organisations.map(org => ({
                                                    value: org.id,  // Use organisation ID for value
                                                    label: org.organisation_name // Use organisation_name for display label
                                                }))}
                                                value={organisations.find(org => org.id === projectData.associated_organisation)
                                                    ? {
                                                        value: projectData.associated_organisation,
                                                        label: organisations.find(org => org.id === projectData.associated_organisation)?.organisation_name
                                                    }
                                                    : null} // Ensure the value is an object with 'value' and 'label'
                                                onChange={(selectedOption) => {
                                                    setProjectData(prevData => ({
                                                        ...prevData,
                                                        associated_organisation: selectedOption.value // Update with selected organisation's ID
                                                    }));
                                                }}
                                                placeholder="Select Organisation"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Project Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="project_name"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Report Category</label>
                                            <select
                                                className="form-select"
                                                name="report_category"
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select a category</option>
                                                <option value="Asset Tracing">Asset Tracing</option>
                                                <option value="Due Diligence ">Due Diligence</option>
                                                <option value="Background Check">Background Check</option>
                                                <option value="Social Media Analysis">Social Media Analysis</option>
                                                <option value="Cyber Forensics">Cyber Forensics</option>
                                                <option value="Trade Finance Risk ">Trade Finance Risk </option>
                                                <option value="Custom Investigation Report">Custom Investigation Report</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Assign To</label>
                                            <Select
                                                name="assigned_to"
                                                options={employees.map(emp => ({
                                                    value: emp.employee_id,
                                                    label: emp.name
                                                }))}
                                                value={employees.filter(emp => projectData.assigned_to.includes(emp.employee_id)).map(emp => ({
                                                    value: emp.employee_id,
                                                    label: emp.name
                                                }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setProjectData(prevData => ({
                                                        ...prevData,
                                                        assigned_to: selectedIds
                                                    }));
                                                }}
                                                isMulti
                                            />
                                        </div>
                                        <div className="mb-3 text-center">
                                            <button type="submit" className="btn btn-dark">Create Project</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Edit Project Modal */}
                    <Modal show={showEdit} onHide={handleCloseEdit} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Project</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleUpdate}>
                                <div className="mb-3">
                                    <label className="form-label">Organisation Name</label>
                                    <Select
                                        name="associated_organisation"
                                        options={organisations.map(org => ({
                                            value: org.id,  // Use organisation ID for value
                                            label: org.organisation_name // Use organisation_name for display
                                        }))}
                                        value={organisations.find(org => org.id === projectData.associated_organisation)
                                            ? { value: projectData.associated_organisation, label: organisations.find(org => org.id === projectData.associated_organisation).organisation_name }
                                            : null} // Ensure correct organisation is selected
                                        onChange={(selectedOption) => {
                                            setProjectData(prevData => ({
                                                ...prevData,
                                                associated_organisation: selectedOption.value // Update the state with the selected organisation's ID
                                            }));
                                        }}
                                        placeholder="Select Organisation"
                                    />

                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Project Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="project_name"
                                        value={projectData.project_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Report Category</label>
                                    <select
                                        className="form-select"
                                        name="report_category"
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={projectData.report_category}>{projectData.report_category}</option>
                                        <option value="Asset Tracing">Asset Tracing</option>
                                        <option value="Due Diligence ">Due Diligence</option>
                                        <option value="Background Check">Background Check</option>
                                        <option value="Social Media Analysis">Social Media Analysis</option>
                                        <option value="Cyber Forensics">Cyber Forensics</option>
                                        <option value="Trade Finance Risk ">Trade Finance Risk </option>
                                        <option value="Custom Investigation Report">Custom Investigation Report</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Assign To</label>
                                    <Select
                                        name="assigned_to"
                                        options={employees.map(emp => ({
                                            value: emp.employee_id,
                                            label: emp.name
                                        }))}
                                        value={employees
                                            .filter(emp => projectData.assigned_to.includes(emp.employee_id.toString()))
                                            .map(emp => ({ value: emp.employee_id, label: emp.name }))}
                                        onChange={(selectedOptions) => {
                                            const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                            setProjectData(prevData => ({
                                                ...prevData,
                                                assigned_to: selectedIds
                                            }));
                                        }}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        placeholder="Select users to assign..."
                                    />
                                </div>
                                <div className="mb-3 text-center">
                                    <button type="submit" className="btn btn-dark">Update Project</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>


                    {/* Delete Project Modal */}
                    <Modal show={showDelete} onHide={handleCloseDelete} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Project</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete the project <strong>{currentProject?.project_name}</strong>?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCloseDelete}>Cancel</button>
                            <button className="btn btn-danger" onClick={handleDelete}>Delete</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default Projects;