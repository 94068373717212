// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import JoditEditor from "jodit-react";
// import "../../common.css";
// import Sidebar from "./AdminSidebar";
// import { useAuth } from '../../AuthContext';
// import { Modal, Button, Form } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

// function CreateReport({ setReportData }) {
//   const { id } = useParams(); // Get template ID from URL
//   const navigate = useNavigate();

//   const { apipath } = useAuth();
//   const token = localStorage.getItem('adminaccessToken');

//   // State declarations
//   const [subHeading, setSubHeading] = useState("");
//   const [errors, setErrors] = useState({});
//   const [reportFields, setReportFields] = useState([]);
//   const [sections, setSections] = useState([]);
//   const [textInput, setTextInput] = useState(""); // Template name input
//   const [showModal, setShowModal] = useState(false); // Modal state
//   const [projects, setProjects] = useState([]); // Project names
//   const [selectedProject, setSelectedProject] = useState(""); // Selected project
//   const [reportName, setReportName] = useState(""); // Report name in modal

//   // Fetch template data by ID when component mounts
//   useEffect(() => {
//     const fetchTemplateById = async () => {
//       try {
//         const response = await fetch(`${apipath}/templates/${id}/`, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         if (response.ok) {
//           const templateData = await response.json();
//           setTextInput(templateData.name); // Set template name
//           setReportFields(templateData.template_structure.reportFields);
//           setSections(templateData.template_structure.sections);
//           setSubHeading(templateData.subHeading || ""); // Assuming you have a subheading
//         } else {
//           console.error("Failed to fetch template data.");
//         }
//       } catch (error) {
//         console.error("Error fetching template data:", error);
//       }
//     };

//     fetchTemplateById();
//   }, [apipath, token, id]);

//   // Fetch project names for the dropdown
//   useEffect(() => {
//     const fetchProjects = async () => {
//       try {
//         const response = await fetch(`${apipath}/projects/`, {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         if (response.ok) {
//           const data = await response.json();
//           setProjects(data); // Assuming API returns a projects array

//         } else {
//           console.error("Failed to fetch project names.");
//         }
//       } catch (error) {
//         console.error("Error fetching projects:", error);
//       }
//     };

//     fetchProjects();
//   }, [apipath, token]);

//   const submitReportData = async (templateData) => {
//     try {
//       const reportPayload = {
//         name: templateData.name, // Report name from modal
//         project: templateData.project, // Selected project ID
//         template: id, // Template ID from useParams()
//         content: {
//           reportFields: templateData.template_structure.reportFields,
//           sections: templateData.template_structure.sections,
//         }, // Sending the report fields and sections as JSON
//       };

//       const response = await fetch(`${apipath}/reports/create/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(reportPayload), // Pass the constructed payload
//       });

//       if (response.ok) {
//         console.log("Report created successfully!");
//         navigate("/admin-reports"); // Redirect on success
//       } else {
//         const errorData = await response.json();
//         console.error("Error creating report:", errorData);
//         setErrors(errorData); // Display errors
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
// };

//   // Handle save button (shows modal)
//   const handleSaveReport = () => {
//     setShowModal(true); // Show modal on click
//   };

//   // Handle modal form submission
//   const handleModalSubmit = () => {
//     const templateData = {
//       name: reportName, // Template name from the modal input
//       template_structure: {
//         reportFields,
//         sections,
//       },
//       project: selectedProject, // Add selected project
//     };

//     // Send data to the backend
//     submitReportData(templateData);
//     setShowModal(false); // Hide modal
//   };

//   const handleSubHeadingChange = (e) => {
//     setSubHeading(e.target.value);
//   };

//   const stripParagraphTags = (data) => {
//     return data.replace(/<p[^>]*>/g, "\n").replace(/<\/p>/g, "").trim();
//   };

//   const handleReportFieldChange = (index, value) => {
//     const updatedFields = [...reportFields];
//     updatedFields[index].value = value;
//     setReportFields(updatedFields);
//   };

//   const handleChange = (sectionId, fieldIndex, fieldKey, value) => {
//     const updatedSections = sections.map((section) => {
//       if (section.id === sectionId) {
//         const updatedFields = [...section.fields];
//         updatedFields[fieldIndex] = {
//           ...updatedFields[fieldIndex],
//           [fieldKey]: value,
//         };
//         return { ...section, fields: updatedFields };
//       }
//       return section;
//     });
//     setSections(updatedSections);
//   };

//   // Add new section
//   const handleAddSection = () => {
//     const newSection = {
//       id: `section-${sections.length + 1}`,
//       title: `New Section ${sections.length + 1}`,
//       fields: [
//         {
//           id: `field-1`,
//           content: "",
//         },
//       ],
//     };
//     setSections([...sections, newSection]);
//   };

//   // Remove section
//   const handleRemoveSection = (sectionId) => {
//     const updatedSections = sections.filter(section => section.id !== sectionId);
//     setSections(updatedSections);
//   };

//   // Add new field to a section
//   const handleAddField = (sectionId) => {
//     const updatedSections = sections.map((section) => {
//       if (section.id === sectionId) {
//         const updatedFields = [
//           ...section.fields,
//           { id: `field-${section.fields.length + 1}`, content: "" },
//         ];
//         return { ...section, fields: updatedFields };
//       }
//       return section;
//     });
//     setSections(updatedSections);
//   };

//   // Remove field from a section
//   const handleRemoveField = (sectionId, fieldIndex) => {
//     const updatedSections = sections.map((section) => {
//       if (section.id === sectionId) {
//         const updatedFields = section.fields.filter((_, index) => index !== fieldIndex);
//         return { ...section, fields: updatedFields };
//       }
//       return section;
//     });
//     setSections(updatedSections);
//   };

//   return (
//     <>
//       <Sidebar />
//       <div className="oldtemplate-container">
//         <header className="oldtemplate-header">
//           <img
//             src={`${process.env.PUBLIC_URL}/images/athena1.png`}
//             alt="Athena Logo"
//             className="oldtemplate-image"
//           />
//           <span className="ishan">ATHENA INTELLIGENCE</span>
//         </header>

//         <div className="due-diligence-form">
//           <div className="heading-container">
//             <input
//               type="text"
//               value={subHeading}
//               onChange={handleSubHeadingChange}
//               placeholder="Edit template"
//               className="main-heading input-centered"
//             />
//             {errors.subHeading && (
//               <span className="error">{errors.subHeading}</span>
//             )}
//           </div>

//           {/* Report Fields Section */}
//           <div className="report-fields">
//             <h2>Report Fields</h2>
//             {reportFields.map((field, index) => (
//               <div key={index} className="report-field-container">
//                 <label>{field.title}</label>
//                 <input
//                   type="text"
//                   value={field.value}
//                   onChange={(e) => handleReportFieldChange(index, e.target.value)}
//                   placeholder={`Enter ${field.title.toLowerCase()}`}
//                 />
//               </div>
//             ))}
//           </div>

//           {/* Sections Management */}
//           <div className="sections-container">
//             <h2>Sections</h2>
//             {sections.map((section, sectionIndex) => (
//               <div key={section.id} className="section-container">
//                 <h3>{section.title}</h3>

//                 {/* Add Field Button */}
//                 <button onClick={() => handleAddField(section.id)} className="add-field-button">
//                   Add Field
//                 </button>

//                 {/* Render Fields */}
//                 {section.fields.map((field, fieldIndex) => (
//                   <div key={fieldIndex} className="field-container">
//                     <JoditEditor
//                       value={field.content}
//                       onBlur={(newContent) => handleChange(section.id, fieldIndex, "content", newContent)}
//                     />

//                     {/* Remove Field Button */}
//                     <button
//                       onClick={() => handleRemoveField(section.id, fieldIndex)}
//                       className="remove-field-button"
//                     >
//                       Remove Field
//                     </button>
//                   </div>
//                 ))}

//                 {/* Remove Section Button */}
//                 <button
//                   onClick={() => handleRemoveSection(section.id)}
//                   className="remove-section-button"
//                 >
//                   Remove Section
//                 </button>
//               </div>
//             ))}

//             {/* Add Section Button */}
//             <button onClick={handleAddSection} className="add-section-button">
//               Add Section
//             </button>
//           </div>

//           {/* Save Report Button */}
//           <button className="save-report-button" onClick={handleSaveReport}>
//             Save Report
//           </button>
//         </div>
//       </div>

//       {/* Modal for saving report */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Save Report</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="reportName">
//               <Form.Label>Report Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter report name"
//                 value={reportName}
//                 onChange={(e) => setReportName(e.target.value)}
//               />
//             </Form.Group>
//             <Form.Group controlId="projectSelect">
//               <Form.Label>Select Project</Form.Label>
//               <Form.Control
//                 as="select"
//                 value={selectedProject}
//                 onChange={(e) => setSelectedProject(e.target.value)}
//               >
//                 <option value="">Select a project</option>
//                 {projects.map((project) => (
//                   <option key={project.id} value={project.id}>
//                     {project.organisation_name}
//                   </option>
//                 ))}
//               </Form.Control>
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>
//             Cancel
//           </Button>
//           <Button variant="primary" onClick={handleModalSubmit}>
//             Save Report
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default CreateReport;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import "../../common.css";
import Sidebar from "./AdminSidebar";
import { useAuth } from "../../AuthContext";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported

function CreateReport({ setReportData }) {
  const { id } = useParams(); // Get template ID from URL
  const navigate = useNavigate();

  const { apipath } = useAuth();
  const token = localStorage.getItem("adminaccessToken");

  // State declarations
  const [subHeading, setSubHeading] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [reportFields, setReportFields] = useState([]);
  const [sections, setSections] = useState([]);
  const [textInput, setTextInput] = useState(""); // Template name input
  const [showModal, setShowModal] = useState(false); // Modal state
  const [projects, setProjects] = useState([]); // Project names
  const [selectedProject, setSelectedProject] = useState(""); // Selected project
  const [reportName, setReportName] = useState(""); // Report name in modal

  // drag and down  new created

  const [dynamicFieldTypes, setDynamicFieldTypes] = useState([]);
  const [draggedSectionId, setDraggedSectionId] = useState(null);
  const [draggedFieldIndex, setDraggedFieldIndex] = useState(null);

  // Fetch template data by ID when component mounts
  useEffect(() => {
    const fetchTemplateById = async () => {
      try {
        const response = await fetch(`${apipath}/templates/${id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const templateData = await response.json();
          setTextInput(templateData.name); // Set template name
          setReportFields(templateData.template_structure.reportFields);
          setSections(templateData.template_structure.sections);
          setSubHeading(templateData.template_structure.subHeading); // Assuming you have a subheading
        } else {
          console.error("Failed to fetch template data.");
        }
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchTemplateById();
  }, [apipath, token, id]);

  // Fetch project names for the dropdown
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${apipath}/projects/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProjects(data); // Assuming API returns a projects array
        } else {
          console.error("Failed to fetch project names.");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [apipath, token]);
  // Dynamic fields management
  const addDynamicFieldType = () => {
    setDynamicFieldTypes([...dynamicFieldTypes, { id: Date.now(), name: "" }]);
  };

  // Drag-and-drop handlers for sections
  const handleDragStart = (sectionId) => {
    setDraggedSectionId(sectionId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (sectionId) => {
    const draggedIndex = sections.findIndex(
      (section) => section.id === draggedSectionId
    );
    const targetIndex = sections.findIndex(
      (section) => section.id === sectionId
    );
    const updatedSections = [...sections];
    const [draggedSection] = updatedSections.splice(draggedIndex, 1);
    updatedSections.splice(targetIndex, 0, draggedSection);
    setSections(updatedSections);
    setDraggedSectionId(null);
  };

  // Drag-and-drop handlers for report fields
  const handleFieldDragStart = (e, index) => {
    setDraggedFieldIndex(index);
  };

  const handleFieldDrop = (e, index) => {
    e.preventDefault();
    if (draggedFieldIndex !== null && draggedFieldIndex !== index) {
      const updatedFields = [...reportFields];
      const [draggedField] = updatedFields.splice(draggedFieldIndex, 1);
      updatedFields.splice(index, 0, draggedField);
      setReportFields(updatedFields);
      setDraggedFieldIndex(null);
    }
  };

  const submitReportData = async (templateData) => {
    try {
      const reportPayload = {
        name: templateData.name, // Report name from modal
        project: templateData.project, // Selected project ID
        template: id, // Template ID from useParams()
        content: {
          subHeading: templateData.template_structure.subHeading,
          reportFields: templateData.template_structure.reportFields,
          sections: templateData.template_structure.sections,
        }, // Sending the report fields and sections as JSON
      };
      const response = await fetch(`${apipath}/reports/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reportPayload), // Pass the constructed payload
      });

      if (response.ok) {
        console.log("Report created successfully!");
        setLoading(false);
        navigate("/admin-reports"); // Redirect on success
      } else {
        const errorData = await response.json();
        console.error("Error creating report:", errorData);
        setErrors(errorData); // Display errors
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle save button (shows modal)
  const handleSaveReport = () => {
    setShowModal(true); // Show modal on click
  };

  // Handle modal form submission
  const handleModalSubmit = () => {
    setLoading(true);
    const templateData = {
      name: reportName, // Template name from the modal input
      template_structure: {
        subHeading,
        reportFields,
        sections,
      },
      project: selectedProject, // Add selected project
    };

    // Send data to the backend
    submitReportData(templateData);
    setShowModal(false); // Hide modal
  };

  const handleSubHeadingChange = (e) => {
    setSubHeading(e.target.value);
  };

  const handleReportFieldChange = (index, value) => {
    const updatedFields = [...reportFields];
    updatedFields[index].value = value;
    setReportFields(updatedFields);
  };

  const handleChange = (sectionId, fieldIndex, fieldKey, value) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = [...section.fields];
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          [fieldKey]: value,
        };
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  // Add new section
  const handleAddSection = () => {
    const newSection = {
      id: `section-${sections.length + 1}`,
      title: `New Section ${sections.length + 1}`,
      fields: [
        {
          id: `field-1`,
          content: "",
        },
      ],
      editable: true, // Mark the new section title as editable by default
    };
    setSections([...sections, newSection]);
  };
  // Remove section
  const handleRemoveSection = (sectionId) => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
  };

  // Add new field to a section
  const handleAddField = (sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = [
          ...section.fields,
          { id: `field-${section.fields.length + 1}`, content: "" },
        ];
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  // Remove field from a section
  const handleRemoveField = (sectionId, fieldIndex) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = section.fields.filter(
          (_, index) => index !== fieldIndex
        );
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };
  const renderInputs = (
    label,
    value,
    fieldKey,
    sectionId,
    fieldIndex,
    placeholderPrefix,
    editablePlaceholder // New parameter for editable placeholder
  ) => (
    <div className="input-group">
      <label>{label}</label>
      <input
        type="text"
        value={Array.isArray(value) ? value.join(", ") : value} // Join if it's an array
        onChange={(e) =>
          handleChange(sectionId, fieldIndex, fieldKey, e.target.value)
        }
        placeholder={`${placeholderPrefix} ${
          editablePlaceholder || label.toLowerCase()
        }`} // Use editable placeholder
      />
    </div>
  );
  return (
    <>
      <Sidebar />
      <div className="oldtemplate-container">
        <header className="oldtemplate-header">
          <img
            src={`${process.env.PUBLIC_URL}/images/athena1.png`}
            alt="Athena Logo"
            className="oldtemplate-image"
          />
          <span className="ishan">ATHENA INTELLIGENCE</span>
        </header>

        <div className="due-diligence-form">
          <div className="heading-container">
            <input
              type="text"
              value={subHeading}
              onChange={handleSubHeadingChange}
              placeholder="Edit template subheading"
              className="main-heading input-centered"
            />
            {errors.subHeading && (
              <span className="error">{errors.subHeading}</span>
            )}
          </div>

          {/* Report Fields Section */}
          <div className="report-fields">
            <h2>Report Fields</h2>
            {reportFields.map((field, index) => (
              <div
                key={index}
                className="report-field-container"
                draggable
                onDragStart={(e) => handleFieldDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleFieldDrop(e, index)}
              >
                <label>{field.title}</label>
                <input
                  type="text"
                  value={field.value}
                  onChange={(e) =>
                    handleReportFieldChange(index, e.target.value)
                  }
                  placeholder={`Enter ${field.title.toLowerCase()}`}
                />
              </div>
            ))}
          </div>

          {/* Sections Management */}
          <div className="sections-container">
            <h2>Sections</h2>
            {sections.map((section) => (
              <div
                key={section.id}
                className="section-container"
                draggable
                onDragStart={() => handleDragStart(section.id)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(section.id)}
              >
                {section.editable ? (
                  <input
                    type="text"
                    value={section.title}
                    onChange={(e) =>
                      setSections((prevSections) =>
                        prevSections.map((s) =>
                          s.id === section.id
                            ? { ...s, title: e.target.value }
                            : s
                        )
                      )
                    }
                    className="section-title-input"
                    placeholder="Enter section title"
                    onBlur={() =>
                      setSections((prevSections) =>
                        prevSections.map((s) =>
                          s.id === section.id ? { ...s, editable: false } : s
                        )
                      )
                    } // Switch back to non-editable mode on blur
                  />
                ) : (
                  <h3
                    onClick={() =>
                      setSections((prevSections) =>
                        prevSections.map((s) =>
                          s.id === section.id ? { ...s, editable: true } : s
                        )
                      )
                    }
                  >
                    {section.title}
                  </h3>
                )}

                {/* Add Field Button */}
                <button
                  onClick={() => handleAddField(section.id)}
                  className="add-field-button"
                >
                  Add Field
                </button>

                {/* Render Fields */}
                {section.fields.map((field, fieldIndex) => (
                  <div key={fieldIndex} className="field-container">
                    {section.id === "subject-information" ? (
                      <>
                        {field.name !== undefined &&
                          renderInputs(
                            "Name",
                            field.name,
                            "name",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Name" // Editable placeholder
                          )}
                        {field.address !== undefined &&
                          renderInputs(
                            "Address",
                            field.address,
                            "address",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Address" // Editable placeholder
                          )}
                        {field.contact !== undefined &&
                          renderInputs(
                            "Contact",
                            field.contact,
                            "contact",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Contact" // Editable placeholder
                          )}
                        {field.legalStructure !== undefined &&
                          renderInputs(
                            "Legal Structure",
                            field.legalStructure,
                            "legalStructure",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Legal Structure" // Editable placeholder
                          )}
                        {field.registrationNumber !== undefined &&
                          renderInputs(
                            "Registration Number",
                            field.registrationNumber,
                            "registrationNumber",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Registration Number" // Editable placeholder
                          )}
                        {field.keyPersons !== undefined &&
                          renderInputs(
                            "Key Persons",
                            field.keyPersons,
                            "keyPersons",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Key Persons" // Editable placeholder
                          )}
                      </>
                    ) : (
                      <JoditEditor
                        value={field.content}
                        onBlur={(newContent) =>
                          handleChange(
                            section.id,
                            fieldIndex,
                            "content",
                            newContent
                          )
                        }
                        placeholder={`Enter content for ${section.title}`} // Placeholder for JoditEditor
                      />
                    )}

                    {/* Remove Field Button */}
                    <button
                      onClick={() => handleRemoveField(section.id, fieldIndex)}
                      className="remove-field-button"
                    >
                      Remove Field
                    </button>
                  </div>
                ))}

                {/* Remove Section Button */}
                <button
                  onClick={() => handleRemoveSection(section.id)}
                  className="remove-section-button"
                >
                  Remove Section
                </button>
              </div>
            ))}

            {/* Add Section Button */}
            <button onClick={handleAddSection} className="add-section-button">
              Add Section
            </button>
          </div>

          {/* Save Report Button */}
          <button
            type="submit"
            className="save-report-button"
            onClick={handleSaveReport}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Report"}
          </button>
        </div>
      </div>

      {/* Modal for saving report */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="reportName">
              <Form.Label>Report Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter report name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="projectSelect">
              <Form.Label>Select Project</Form.Label>
              <Form.Control
                as="select"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
              >
                <option value="">Select a project</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.project_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Save Report
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateReport;
