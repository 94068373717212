import React, { useEffect, useState } from "react";
import Sidebar from "./AdminSidebar";
import Navbar from "../Navbar";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const Reportstemplate = () => {
    const [templates, setTemplates] = useState([]);
    const { apipath } = useAuth();
    const navigate = useNavigate();

    const token = localStorage.getItem('adminaccessToken');

    useEffect(() => {
        // Fetch the report templates from the backend
        axios.get(`${apipath}/templates/`, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        })
            .then(response => {
                setTemplates(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the templates!", error);
            });
    }, [apipath, token]);

    const handleButtonClick = (templateId) => {
        navigate(`/edit-report-template/${templateId}`);
    };

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="home-main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="report-content">
                                <div className="report-heading">
                                    <h4>Choose Pre-Defined Report Template</h4>
                                    <Link to='/CreateNewTemplate'> <button><i className="bi bi-bar-chart me-2"></i>Create New Report Template</button> </Link>
                                </div>
                                <hr />
                            </div>
                            <div className="row">
                                {templates.map((template, index) => (
                                    <div className="col-lg-3" key={template.id}>
                                        <div className="template-box">
                                            <img src="images/template.png" alt="Template" />
                                            <div className="report-btn">
                                                <button onClick={() => handleButtonClick(template.id)}>{template.name}</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reportstemplate;
