// services/prefilledData.js
const prefilledData = {
    executiveSummary: 'EXECUTIVE SUMMARY',
    // tableData: [
    //   ['Column 1', 'Column 2', 'Column 3'],  // Header row
    //   ['Data 1.1', 'Data 1.2', 'Data 1.3'],  // Data row 1
    //   ['Data 2.1', 'Data 2.2', 'Data 2.3'],  // Data row 2
    //   // ... more rows
    // ],
    dramatisPersonae: {
      fullName: 'FULL NAME',
      address: 'FULL ADDRESS',
      dob: 'DATE OF BIRTH',
      phones: ['+66830472892', '+447700000100', '+972525344377'],
      emails: ['EMAIL 1', 'EMAIL 2'],
      socialMedia: {
        facebook: 'facebook.com/DavcceidRNataf',
        linkedin: 'linkedin.com/in/dceavidnataf',
      },
      placeOfBirth: 'Paris, France',
      corporateAffiliations: {
        country1: 'Adamas Cybersecurite Inc.',
        country2: 'CaratExchange OU',
        country3:'hgcefg'
        // Other affiliations...
      },
      sourceOfWealth:'sorce of wealth',
      availablityOfInformation:'level(low-high)'

    },
    corporateAffiliationsDiscription:{}
      // canada: {
      //       name: 'Adamas Cybersecurite Inc. (14875570)',
      //       incorporationDate: '31.05.2021 – Active',
      //       directors: ['Jerome Coutard – Director', 'Ollivier Gimenez Espinos – Director', 'Beaudry Luc – Director', 'David Nataf – Director', 'Veronique Queffelec – Director', 'Francois Sauve – Director', 'Stephane Toupin – Director'],
      //       shareholders: 'N/A',
      //       address: '195 Rue Brissette, Bureau No. 72, Sainte-Agathe-des-Monts, Quebec J8C 3S4, Canada',
      //       mainActivities: 'N/A',
      //       description: 'Adamas Cybersecurite Inc. was incorporated on 31 May 2021 in Quebec, Canada. Jerome Coutard, Ollivier Gimenez Espinos, Beaudry Luc, David Nataf, Veronique Queffelec, Francois Sauve, and Stephane Toupin manage the company. The Canadian corporate register or other online sources have not disclosed its shareholders.'
      //     },
      //     estonia: {
      //       name: 'CaratExchange OU (14875570)',
      //       incorporationDate: '27.12.2019 – Active',
      //       directors: ['David Nataf – Manager'],
      //       shareholders: 'David Nataf – 100%',
      //       address: 'Harju maakond, Tallinn, Mustamae linnaosa, Laki tn 30, 12915, Estonia',
      //       mainActivities: 'Financial services',
      //       description: 'CaratExchange OU was incorporated on 27 December 2019 in Tallinn, Estonia. It focuses on financial services activities. David Nataf manages the company and is its sole shareholder.'
      //     },
      //     france: [
      //       {
      //         name: 'NATAF David Robert Hai (913613824)',
      //         incorporationDate: '18.05.2022 – Active',
      //         directors: ['David Nataf – Manager'],
      //         shareholders: 'David Nataf – 100%',
      //         address: '4 Rue des Marronniers, 74100 Annemasse, France',
      //         mainActivities: 'Meal delivery by bike',
      //         description: 'NATAF David Robert Hai was incorporated on 18 May 2022 in Annemasse, France. It focuses on meal delivery by bike. David Nataf manages the company and is its sole shareholder.'
      //       },
      //       {
      //         name: 'SCI Muette Desbordes Valmore (913613825)',
      //         incorporationDate: '26.09.1960 – Active',
      //         directors: ['David Nataf – Manager and Partner', 'Judith Nataf – Not specified', 'Germaine Raux – Not specified'],
      //         shareholders: 'David Nataf, Judith Nataf, Germaine Raux',
      //         address: '38 Rue Desbordes Valmore, 75016 Paris, France',
      //         mainActivities: 'Real estate',
      //         description: 'SCI Muette Desbordes Valmore was incorporated on 26 September 1960 in Paris, France. It focuses on real estate activities. David Nataf, Judith Natad, and Germaine Raux manage the company and are its shareholders.'
      //       }
      //     ],
      //     singapore: {
      //       name: 'Salamandre Consultancy Pte. Ltd. (14875571)',
      //       incorporationDate: '14.03.2016 – Active',
      //       directors: ['David Nataf – Director (since 14.03.2016)', 'Peter Chong Siang Song – Director (since 14.03.2016)'],
      //       shareholders: 'David Nataf – 100%',
      //       address: '3 Raffles Place, #06-01 Bharat Building, Singapore 048617',
      //       mainActivities: ['Management consultancy', 'Wholesale of jewellery'],
      //       description: 'Salamandre Consultancy Pte. Ltd. was incorporated on 14 March 2016 in Singapore. It focuses on (i) management consultancy activities and (ii) the wholesale of jewelry made from precious metals and stones. David Nataf and Peter Chong Siang Song manage the company. Mr. Nataf is also its sole shareholder.'
      //     },
      //     switzerland: {
      //       name: 'Fondation Afro (14875572)',
      //       incorporationDate: '15.05.2019 – Active',
      //       directors: ['David Nataf – President (since 20.05.2019)', 'Roland Coutas – Vice-President (since 20.05.2019)', 'Pierre Pesce – Board Member (since 20.08.2019)', 'Formerly: Francois Chevalier – Board Member (20.05.2019-30.06.2019)'],
      //       shareholders: 'N/A',
      //       address: 'Rue Neuve-du-Molard 8, C/O Pramadex SA, 1204 Geneva, Switzerland',
      //       mainActivities: 'Foundation',
      //       description: 'Fondation Afro was incorporated on 15 May 2019 in Geneva, Switzerland. It is a foundation aiming to ensure societal development and the responsible improvement of daily well-being on the African continent. David Nataf (President), Roland Coutas (Vice-President), and Pierre Pesce (Board Member) manage the foundation. You may note that its website was registered one year before the foundation was incorporated.'
      //     },
      //     uk: [
      //       {
      //         name: 'Nextadvantage Limited (14875573)',
      //         incorporationDate: '21.08.2020 – Active',
      //         directors: ['David Nataf – Director (since 21.08.2020)', 'Herve Omore – Director (since 21.08.2020)'],
      //         shareholders: 'David Nataf – 50%, Herve Omore – 50%',
      //         address: '8 Quarles Park Road, Romford RM6 4DE, UK',
      //         mainActivities: 'Information technology consultancy',
      //         description: 'Nextadvantage Limited was incorporated on 21 August 2020 in Romford, UK. It focuses on information technology consultancy activities. David Nataf and Herve Omore manage the company and are its shareholders (50% each). Regarding financial performance, according to its latest financial statements filed to the British corporate register in 2022, Nextadvantage had shareholder funds of GBP 2.'
      //       },
      //       {
      //         name: 'Salamandre Limited (14875574)',
      //         incorporationDate: '24.07.2015 – Active',
      //         directors: ['David Nataf – Director (since 24.07.2015)'],
      //         shareholders: 'David Nataf – 100%',
      //         address: '8 Quarles Park Road, Romford RM6 4DE, UK',
      //         mainActivities: 'Management consultancy',
      //         description: 'Salamandre Limited was incorporated on 24 July 2015 in Romford, UK. It focuses on management consultancy activities. David Nataf manages the company and is its sole shareholder. Regarding financial performance, according to its latest financial statements filed to the British corporate register in 2022, Salamandre had shareholder funds of GBP 1.'
      //       }
      //     ]

    // }
    // Other prefilled data...
  };
  
  export default prefilledData;
  
// // // services/prefilledData.js
// // // const prefilledData = {
// // //   executiveSummary: '',
// // //   tableData: [
// // //     // ['Column 1', 'Column 2', 'Column 3'],  // Header row
// // //     // ['Data 1.1', 'Data 1.2', 'Data 1.3'],  // Data row 1
// // //     // ['Data 2.1', 'Data 2.2', 'Data 2.3'],  // Data row 2
// // //     // ... more rows
// // //   ],
// // //   dramatisPersonae: {
// // //     fullName: '',
// // //     address: '',
// // //     dob: '',
// // //     phones: [],
// // //     emails: [],
// // //     socialMedia: {
// // //       facebook: '',
// // //       linkedin: '',
// // //     },
// // //     placeOfBirth: '',
// // //     corporateAffiliations: {
// // //       canada: '',
// // //       estonia: '',
// // //       // Other affiliations...
// // //     },
// // //   },
// // //   // Other prefilled data...
// // // };

// // // export default prefilledData;

// const prefilledData = {
//   executiveSummary: 'EXECUTIVE SUMMARY',
//   tableData: [
//     ['Column 1', 'Column 2', 'Column 3'],  // Header row
//     ['Data 1.1', 'Data 1.2', 'Data 1.3'],  // Data row 1
//     ['Data 2.1', 'Data 2.2', 'Data 2.3'],  // Data row 2
//     // ... more rows
//   ],
//   dramatisPersonae: {
//     fullName: 'David Robert Hai Nataf',
//     address: 'Rua Senhora Da Luz 24, Porto 4150-693, Portugal',
//     dob: '09.06.1970',
//     phones: ['+66830472892', '+447700000100', '+972525344377'],
//     emails: ['david@nataf.com', 'nataf.david@gmail.com'],
//     socialMedia: {
//       facebook: 'facebook.com/DavidRNataf',
//       linkedin: 'linkedin.com/in/davidnataf',
//     },
//     placeOfBirth: 'Paris, France',
//     corporateAffiliations: {
//       country1: 'Adamas Cybersecurite Inc.',
//       country2: 'CaratExchange OU',
//       country3: 'hgcefg'
//       // Other affiliations...
//     },
//     sourceOfWealth: 'sorce of wealth',
//     availablityOfInformation: 'level(low-high)'
//   },
//   corporateAffiliationsDescription: {
//     canada: {
//       name: 'Adamas Cybersecurite Inc. (14875570)',
//       incorporationDate: '31.05.2021 – Active',
//       directors: ['Jerome Coutard – Director', 'Ollivier Gimenez Espinos – Director', 'Beaudry Luc – Director', 'David Nataf – Director', 'Veronique Queffelec – Director', 'Francois Sauve – Director', 'Stephane Toupin – Director'],
//       shareholders: 'N/A',
//       address: '195 Rue Brissette, Bureau No. 72, Sainte-Agathe-des-Monts, Quebec J8C 3S4, Canada',
//       mainActivities: 'N/A',
//       description: 'Adamas Cybersecurite Inc. was incorporated on 31 May 2021 in Quebec, Canada. Jerome Coutard, Ollivier Gimenez Espinos, Beaudry Luc, David Nataf, Veronique Queffelec, Francois Sauve, and Stephane Toupin manage the company. The Canadian corporate register or other online sources have not disclosed its shareholders.'
//     },
//     estonia: {
//       name: 'CaratExchange OU (14875570)',
//       incorporationDate: '27.12.2019 – Active',
//       directors: ['David Nataf – Manager'],
//       shareholders: 'David Nataf – 100%',
//       address: 'Harju maakond, Tallinn, Mustamae linnaosa, Laki tn 30, 12915, Estonia',
//       mainActivities: 'Financial services',
//       description: 'CaratExchange OU was incorporated on 27 December 2019 in Tallinn, Estonia. It focuses on financial services activities. David Nataf manages the company and is its sole shareholder.'
//     },
//     france: [
//       {
//         name: 'NATAF David Robert Hai (913613824)',
//         incorporationDate: '18.05.2022 – Active',
//         directors: ['David Nataf – Manager'],
//         shareholders: 'David Nataf – 100%',
//         address: '4 Rue des Marronniers, 74100 Annemasse, France',
//         mainActivities: 'Meal delivery by bike',
//         description: 'NATAF David Robert Hai was incorporated on 18 May 2022 in Annemasse, France. It focuses on meal delivery by bike. David Nataf manages the company and is its sole shareholder.'
//       },
//       {
//         name: 'SCI Muette Desbordes Valmore (913613825)',
//         incorporationDate: '26.09.1960 – Active',
//         directors: ['David Nataf – Manager and Partner', 'Judith Nataf – Not specified', 'Germaine Raux – Not specified'],
//         shareholders: 'David Nataf, Judith Nataf, Germaine Raux',
//         address: '38 Rue Desbordes Valmore, 75016 Paris, France',
//         mainActivities: 'Real estate',
//         description: 'SCI Muette Desbordes Valmore was incorporated on 26 September 1960 in Paris, France. It focuses on real estate activities. David Nataf, Judith Natad, and Germaine Raux manage the company and are its shareholders.'
//       }
//     ],
//     singapore: {
//       name: 'Salamandre Consultancy Pte. Ltd. (14875571)',
//       incorporationDate: '14.03.2016 – Active',
//       directors: ['David Nataf – Director (since 14.03.2016)', 'Peter Chong Siang Song – Director (since 14.03.2016)'],
//       shareholders: 'David Nataf – 100%',
//       address: '3 Raffles Place, #06-01 Bharat Building, Singapore 048617',
//       mainActivities: ['Management consultancy', 'Wholesale of jewellery'],
//       description: 'Salamandre Consultancy Pte. Ltd. was incorporated on 14 March 2016 in Singapore. It focuses on (i) management consultancy activities and (ii) the wholesale of jewelry made from precious metals and stones. David Nataf and Peter Chong Siang Song manage the company. Mr. Nataf is also its sole shareholder.'
//     },
//     switzerland: {
//       name: 'Fondation Afro (14875572)',
//       incorporationDate: '15.05.2019 – Active',
//       directors: ['David Nataf – President (since 20.05.2019)', 'Roland Coutas – Vice-President (since 20.05.2019)', 'Pierre Pesce – Board Member (since 20.08.2019)', 'Formerly: Francois Chevalier – Board Member (20.05.2019-30.06.2019)'],
//       shareholders: 'N/A',
//       address: 'Rue Neuve-du-Molard 8, C/O Pramadex SA, 1204 Geneva, Switzerland',
//       mainActivities: 'Foundation',
//       description: 'Fondation Afro was incorporated on 15 May 2019 in Geneva, Switzerland. It is a foundation aiming to ensure societal development and the responsible improvement of daily well-being on the African continent. David Nataf (President), Roland Coutas (Vice-President), and Pierre Pesce (Board Member) manage the foundation. You may note that its website was registered one year before the foundation was incorporated.'
//     },
//     uk: [
//       {
//         name: 'Nextadvantage Limited (14875573)',
//         incorporationDate: '21.08.2020 – Active',
//         directors: ['David Nataf – Director (since 21.08.2020)', 'Herve Omore – Director (since 21.08.2020)'],
//         shareholders: 'David Nataf – 50%, Herve Omore – 50%',
//         address: '8 Quarles Park Road, Romford RM6 4DE, UK',
//         mainActivities: 'Information technology consultancy',
//         description: 'Nextadvantage Limited was incorporated on 21 August 2020 in Romford, UK. It focuses on information technology consultancy activities. David Nataf and Herve Omore manage the company and are its shareholders (50% each). Regarding financial performance, according to its latest financial statements filed to the British corporate register in 2022, Nextadvantage had shareholder funds of GBP 2.'
//       },
//       {
//         name: 'Salamandre Limited (14875574)',
//         incorporationDate: '24.07.2015 – Active',
//         directors: ['David Nataf – Director (since 24.07.2015)'],
//         shareholders: 'David Nataf – 100%',
//         address: '8 Quarles Park Road, Romford RM6 4DE, UK',
//         mainActivities: 'Management consultancy',
//         description: 'Salamandre Limited was incorporated on 24 July 2015 in Romford, UK. It focuses on management consultancy activities. David Nataf manages the company and is its sole shareholder. Regarding financial performance, according to its latest financial statements filed to the British corporate register in 2022, Salamandre had shareholder funds of GBP 1.'
//       }
//     ]
//   }
// };
