import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const apipath = "http://localhost:8000/api";
  const apipath = "https://athena.dgtlinnovations.in:8000/api";

  const login = (employee_id, access, refresh, email, name, role) => {
    localStorage.setItem('employee_id', employee_id);
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
    localStorage.setItem('email', email);
    localStorage.setItem('employeename', name);
    localStorage.setItem('role', role);
    setIsLoggedIn(true);
  };

  const adminlogin = (employee_id, access, refresh, email, name, role) => {
    localStorage.setItem("employee_id", employee_id);
    localStorage.setItem('adminaccessToken', access);
    localStorage.setItem('adminrefreshToken', refresh);
    localStorage.setItem('adminemail', email);
    localStorage.setItem('adminname', name);
    localStorage.setItem('role', role);
    setIsLoggedIn(true);
  }

  const logout = () => {
    localStorage.removeItem('employee_id');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  const adminLogout = () => {
    localStorage.removeItem('employee_id');
    localStorage.removeItem('adminaccessToken');
    localStorage.removeItem('adminrefreshToken');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    setIsLoggedIn(false);
    window.location.href = "/adminlogin";
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, apipath, login, adminlogin, logout, adminLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);