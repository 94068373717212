// components/DownloadReport.js
import React from 'react';
import { saveAs } from 'file-saver';
import {generateReport} from '../Services/reportTemplateService';
import prefilledData from '../Services/prefilledTemplateData';

const DownloadReportTemplate = () => {
  const handleDownload = async () => {
    try {
      const blob = await generateReport(prefilledData);
      saveAs(blob, 'ReportTemplate.docx');
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  return (
    <div>
      <button onClick={handleDownload}>Download Report</button>
    </div>
  );
};

export default DownloadReportTemplate;
