import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import "../../common.css";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  ImageRun,
  Footer,
  Header,
  TableCell,
  TableRow,
  Table,
  WidthType,
  TableRowAlignment,
  BorderStyle,
  SectionType,
  VerticalAlign,
} from "docx";
import { saveAs } from "file-saver";
import Sidebar from "./EmployeeSidebar";
import { useAuth } from "../../AuthContext";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import jsPDF from "jspdf";

function EditReportById() {
  const { id } = useParams(); // Get report ID from URL
  const navigate = useNavigate();

  const { apipath } = useAuth();
  const token = localStorage.getItem("accessToken");

  // State declarations
  const [reportData, setReportData] = useState(null); // To hold fetched report data
  const [loading, setLoading] = useState(false);
  const [subHeading, setSubHeading] = useState("");
  const [errors, setErrors] = useState({});
  const [reportFields, setReportFields] = useState([]);
  const [sections, setSections] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal state
  const [project, setProject] = useState([]); // Project names
  const [selectedProject, setSelectedProject] = useState(""); // Selected project
  const [reportName, setReportName] = useState(""); // Report name in modal

  // drag and down  new created

  const [dynamicFieldTypes, setDynamicFieldTypes] = useState([]);
  const [draggedSectionId, setDraggedSectionId] = useState(null);
  const [draggedFieldIndex, setDraggedFieldIndex] = useState(null);

  // Fetch report data by ID when component mounts
  useEffect(() => {
    const fetchReportById = async () => {
      try {
        const response = await fetch(`${apipath}/reports/${id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const reportData = await response.json();
          setReportData(reportData);
          setSubHeading(reportData.content.subHeading);
          setReportFields(reportData.content.reportFields);
          setSections(reportData.content.sections);
          setReportName(reportData.name);
          setSelectedProject(reportData.project); // Set selected project
        } else {
          console.error("Failed to fetch report data.");
        }
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    fetchReportById();
  }, [apipath, token, id]);

  useEffect(() => {
    if (reportData) {
      setSelectedProject(reportData.project); // Ensure selectedProject is updated from reportData
    }
  }, [reportData]);

  // Fetch project names for the dropdown
  useEffect(() => {
    const fetchProjects = async () => {
      if (!selectedProject) {
        return; // Return early if selectedProject is empty or undefined
      }
      try {
        const response = await fetch(`${apipath}/project/${selectedProject}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProject(data.project_name); // Assuming API returns a projects array
        } else {
          console.error("Failed to fetch project names.");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [apipath, token, selectedProject]);

  // Fetch project names for the dropdown
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${apipath}/projects/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProject(data); // Assuming API returns a projects array
        } else {
          console.error("Failed to fetch project names.");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [apipath, token]);

  const submitReportData = async (templateData) => {
    try {
      const reportPayload = {
        name: templateData.name, // Report name from modal
        project: templateData.project, // Selected project ID
        content: {
          subHeading: templateData.subHeading,
          reportFields: templateData.reportFields,
          sections: templateData.sections,
        }, // Sending the report fields and sections as JSON
      };

      const response = await fetch(`${apipath}/reports/${id}/edit/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reportPayload), // Pass the constructed payload
      });

      if (response.ok) {
        console.log("Report updated successfully!");
        setLoading(false);
        navigate("/employee-reports"); // Redirect on success
      } else {
        const errorData = await response.json();
        console.error("Error updating report:", errorData);
        setErrors(errorData); // Display errors
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Dynamic fields management
  const addDynamicFieldType = () => {
    setDynamicFieldTypes([...dynamicFieldTypes, { id: Date.now(), name: "" }]);
  };

  // Drag-and-drop handlers for sections
  const handleDragStart = (sectionId) => {
    setDraggedSectionId(sectionId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (sectionId) => {
    const draggedIndex = sections.findIndex(
      (section) => section.id === draggedSectionId
    );
    const targetIndex = sections.findIndex(
      (section) => section.id === sectionId
    );
    const updatedSections = [...sections];
    const [draggedSection] = updatedSections.splice(draggedIndex, 1);
    updatedSections.splice(targetIndex, 0, draggedSection);
    setSections(updatedSections);
    setDraggedSectionId(null);
  };

  // Drag-and-drop handlers for report fields
  const handleFieldDragStart = (e, index) => {
    setDraggedFieldIndex(index);
  };

  const handleFieldDrop = (e, index) => {
    e.preventDefault();
    if (draggedFieldIndex !== null && draggedFieldIndex !== index) {
      const updatedFields = [...reportFields];
      const [draggedField] = updatedFields.splice(draggedFieldIndex, 1);
      updatedFields.splice(index, 0, draggedField);
      setReportFields(updatedFields);
      setDraggedFieldIndex(null);
    }
  };

  // Handle save button (shows modal)
  const handleSaveReport = () => {
    setShowModal(true); // Show modal on click
  };

  // Handle modal form submission
  const handleModalSubmit = () => {
    setLoading(true);
    const templateData = {
      name: reportName, // Template name from the modal input
      subHeading,
      reportFields,
      sections,
      project: selectedProject, // Add selected project
    };

    // Send data to the backend
    submitReportData(templateData);
    setShowModal(false); // Hide modal
  };

  const handleSubHeadingChange = (e) => {
    setSubHeading(e.target.value);
  };

  const handleReportFieldChange = (index, value) => {
    const updatedFields = [...reportFields];
    updatedFields[index].value = value;
    setReportFields(updatedFields);
  };

  const handleChange = (sectionId, fieldIndex, fieldKey, value) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = [...section.fields];
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          [fieldKey]: value,
        };
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const handleAddSection = () => {
    const newSection = {
      id: `section-${sections.length + 1}`,
      title: `New Section ${sections.length + 1}`,
      fields: [
        {
          id: `field-1`,
          content: "",
        },
      ],
      editable: true, // Mark the new section title as editable by default
    };
    setSections([...sections, newSection]);
  };

  // Remove section
  const handleRemoveSection = (sectionId) => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
  };

  // Add new field to a section
  const handleAddField = (sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = [
          ...section.fields,
          { id: `field-${section.fields.length + 1}`, content: "" },
        ];
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  // Remove field from a section
  const handleRemoveField = (sectionId, fieldIndex) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = section.fields.filter(
          (_, index) => index !== fieldIndex
        );
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };
  const renderInputs = (
    label,
    value,
    fieldKey,
    sectionId,
    fieldIndex,
    placeholderPrefix,
    editablePlaceholder // New parameter for editable placeholder
  ) => (
    <div className="input-group">
      <label>{label}</label>
      <input
        type="text"
        value={Array.isArray(value) ? value.join(", ") : value} // Join if it's an array
        onChange={(e) =>
          handleChange(sectionId, fieldIndex, fieldKey, e.target.value)
        }
        placeholder={`${placeholderPrefix} ${
          editablePlaceholder || label.toLowerCase()
        }`} // Use editable placeholder
      />
    </div>
  );

  const downloadReport = async () => {
    try {
      const imageResponse = await fetch(
        `${process.env.PUBLIC_URL}/images/athena1.png`
      );
      if (!imageResponse.ok) throw new Error("Image not found");
      const imageData = await imageResponse.arrayBuffer();

      const base64Image = btoa(
        String.fromCharCode(...new Uint8Array(imageData))
      );

      const imageRun = new ImageRun({
        data: base64Image,
        transformation: {
          width: 90,
          height: 120,
        },
      });

      const customHeaderContent = new Paragraph({
        children: [
          imageRun,

          new TextRun({
            text: " ATHENA INTELLIGENCE",
            size: 38,
          }),
        ],

        alignment: AlignmentType.LEFT,
        spacing: { after: 100 },
      });

      const customFooterContent = new Paragraph({
        children: [
          new TextRun({
            text: "Strictly Private & Confidential",
            size: 12,
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: { after: 100 },
      });

      const htmlToContent = (html) => {
        const container = document.createElement("div");
        container.innerHTML = html;

        const contentArray = [];

        const allowedFonts = [
          "Arial",
          "Courier New",
          "Georgia",
          "Lucida Sans Unicode",
          "Tahoma",
          "Times New Roman",
          "Trebuchet MS",
          "Helvetica",
          "Impact",
          "Verdana",
        ];

        const getFontFamily = (element) => {
          const computedFont = window.getComputedStyle(element).fontFamily;
          const font = computedFont.replace(/["']/g, "").split(",")[0].trim();

          return allowedFonts.includes(font) ? font : "Arial";
        };

        container.querySelectorAll("p").forEach((paragraph) => {
          const fontFamily = getFontFamily(paragraph);
          contentArray.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: paragraph.textContent.trim(),
                  size: 24,
                  font: fontFamily,
                }),
              ],
              spacing: { after: 400 },
            })
          );
        });

        container.querySelectorAll("ul").forEach((ul) => {
          ul.querySelectorAll("li").forEach((li) => {
            const fontFamily = getFontFamily(li);
            contentArray.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: `• ${li.textContent.trim()}`,
                    size: 40,
                    font: fontFamily,
                  }),
                ],
                spacing: { after: 200 },
              })
            );
          });
        });

        container.querySelectorAll("ol").forEach((ol) => {
          ol.querySelectorAll("li").forEach((li, index) => {
            const fontFamily = getFontFamily(li);
            contentArray.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${index + 1}. ${li.textContent.trim()}`,
                    size: 40,
                    font: fontFamily,
                  }),
                ],
                spacing: { after: 200 },
              })
            );
          });
        });

        container.querySelectorAll("table").forEach((table) => {
          const rows = table.querySelectorAll("tr");
          const tableRows = [];

          rows.forEach((row) => {
            const cells = row.querySelectorAll("td, th");
            const rowCells = Array.from(cells).map((cell) => {
              const cellContent = [];

              const ul = cell.querySelector("ul");
              if (ul) {
                const listItems = ul.querySelectorAll("li");
                listItems.forEach((item) => {
                  const fontFamily = getFontFamily(item);
                  cellContent.push(
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: item.textContent.trim(),
                          size: 40,
                          font: fontFamily,
                        }),
                      ],
                    })
                  );
                });
              } else {
                const fontFamily = getFontFamily(cell);
                cellContent.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: cell.textContent.trim(),
                        size: 40,
                        font: fontFamily,
                      }),
                    ],
                  })
                );
              }

              const bgColor = window.getComputedStyle(cell).backgroundColor;
              const hexColor = rgbaToHex(bgColor) || "FFFFFF";

              return new TableCell({
                children: cellContent,
                shading: {
                  fill: hexColor,
                },
              });
            });
            tableRows.push(
              new TableRow({
                children: rowCells,
              })
            );
          });

          contentArray.push(
            new Table({
              rows: tableRows,
              width: { size: 100, type: WidthType.PERCENTAGE },
              borders: {
                top: { style: BorderStyle.SINGLE, size: 5, color: "000000" },
                bottom: { style: BorderStyle.SINGLE, size: 5, color: "000000" },
                left: { style: BorderStyle.SINGLE, size: 5, color: "000000" },
                right: { style: BorderStyle.SINGLE, size: 5, color: "000000" },
                inside: { style: BorderStyle.SINGLE, size: 5, color: "000000" },
              },
            })
          );
        });

        function rgbaToHex(rgba) {
          const match = rgba.match(/\d+/g);
          if (!match) return null;
          const r = parseInt(match[0]);
          const g = parseInt(match[1]);
          const b = parseInt(match[2]);
          return ((1 << 24) + (r << 16) + (g << 8) + b)
            .toString(16)
            .slice(1)
            .toUpperCase();
        }

        return contentArray;
      };

      const doc = new Document({
        sections: [
          {
            headers: {
              default: new Header({
                children: [
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new ImageRun({
                                    data: base64Image,
                                    transformation: {
                                      width: 81,
                                      height: 135,
                                    },
                                  }),
                                ],
                                alignment: AlignmentType.RIGHT,
                              }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                              // Remove cell borders
                              top: { size: 0, color: "FFFFFF" },
                              bottom: { size: 0, color: "FFFFFF" },
                              left: { size: 0, color: "FFFFFF" },
                              right: { size: 0, color: "FFFFFF" },
                            },
                          }),

                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: " ATHENA INTELLIGENCE",
                                    bold: false,
                                    size: 48,
                                    font: "sans-serif",
                                    color: "111111",
                                  }),
                                ],
                                alignment: AlignmentType.LEFT,
                                spacing: {
                                  before: 400,
                                },
                              }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                              top: { size: 0, color: "FFFFFF" },
                              bottom: { size: 0, color: "FFFFFF" },
                              left: { size: 0, color: "FFFFFF" },
                              right: { size: 0, color: "FFFFFF" },
                            },
                          }),
                        ],
                      }),
                    ],
                    width: { size: 150, type: WidthType.PERCENTAGE },
                    borders: {
                      top: { size: 0, color: "FFFFFF" },
                      bottom: { size: 0, color: "FFFFFF" },
                      left: { size: 0, color: "FFFFFF" },
                      right: { size: 0, color: "FFFFFF" },
                    },
                  }),
                ],
                spacing: { after: 1800 },
              }),
            },

            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Footer text",
                        text: "Strictly Private & Confidential",
                        bold: false,
                        size: 22,
                        font: "sans-serif",
                        color: "000000",
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
            },

            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: reportName || "Report Title",
                    bold: true,
                    size: 52,
                  }),
                ],
                alignment: AlignmentType.CENTER,
                spacing: { before: 3000 },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: subHeading || "Subheading",
                    bold: true,
                    size: 54,
                  }),
                ],
                alignment: AlignmentType.CENTER,
                spacing: { before: 700 },
              }),
              new Paragraph({ text: "", spacing: { after: 2200 } }),

              ...reportFields.map(
                (field) =>
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${field.title}: ${field.value || "No Data"}`,
                        size: 40,
                      }),
                    ],
                    spacing: { after: 700 },
                  })
              ),
              new Paragraph({ text: "", spacing: { before: 2800 } }),
              ...sections
                .map((section) => {
                  if (section.fields.length > 0) {
                    return [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: section.title || "Section Title",
                            bold: true,
                            size: 44,
                            underline: {
                              type: "single",
                              color: "000000",
                            },
                          }),
                        ],
                        spacing: { after: 1800 },
                      }),
                      ...section.fields.flatMap((field) =>
                        htmlToContent(field.content || "No Content")
                      ),
                      new Paragraph({ text: "", spacing: { after: 700 } }),
                    ];
                  }
                  return [];
                })
                .flat(),
            ],
          },
        ],
      });

      const blob = await Packer.toBlob(doc);
      saveAs(blob, `${reportName || "report"}.docx`);
    } catch (error) {
      console.error("Error generating document:", error);
    }
  };

  // TO DOWNLOAD REPORT IN PDF FORMAT

  const downloadPDFReport = async () => {
    try {
      const imageResponse = await fetch(
        `${process.env.PUBLIC_URL}/images/athena1.png`
      );
      if (!imageResponse.ok) throw new Error("Image not found");
      const imageData = await imageResponse.blob();
      const imageUrl = URL.createObjectURL(imageData);

      const doc = new jsPDF();

      const image = new Image();
      image.src = imageUrl;

      image.onload = () => {
        const stripHtml = (html) => {
          const doc = new DOMParser().parseFromString(html, "text/html");
          return doc.body.textContent || "";
        };

        const addHeader = () => {
          const pageWidth = doc.internal.pageSize.getWidth();
          const imageWidth = 20;
          const imageHeight = 27;
          const imageX = pageWidth / 2 - imageWidth / 2 - 50;

          doc.addImage(image, "PNG", imageX, 2, imageWidth, imageHeight);

          doc.setFontSize(20);
          doc.setTextColor("#111111");

          const text = "ATHENA INTELLIGENCE";
          const textX = pageWidth / 2;
          doc.setTextColor("#999999");
          // Add text for the header
          doc.text(text, textX, 20, null, null, "center");
        };

        const addFooter = () => {
          doc.setFontSize(12);
          doc.setTextColor("#999999");
          doc.text(
            "Strictly Private & Confidential",
            105,
            290,
            null,
            null,
            "center"
          );
        };

        doc.setFontSize(26);
        doc.setTextColor("#000000");
        doc.text(reportName || "Report Title", 105, 60, null, null, "center");

        doc.setFontSize(22);
        doc.text(subHeading || "Subheading", 105, 70, null, null, "center");

        let yOffset = 100;

        const addPageIfNeeded = (currentYOffset) => {
          if (currentYOffset >= 270) {
            doc.addPage();
            addHeader();
            addFooter();
            yOffset = 30;
          }
        };

        addHeader();
        addFooter();

        doc.setFontSize(18);
        doc.setTextColor(0, 0, 0);

        doc.text("Report Fields:", 20, yOffset);
        yOffset += 15;

        reportFields.forEach((field, index) => {
          addPageIfNeeded(yOffset);
          doc.setTextColor(0, 0, 0);

          doc.setFontSize(14);
          const fieldTitle = stripHtml(field.title || "");
          const fieldValue = stripHtml(field.value || "No Data");

          if (fieldTitle) {
            doc.text(`${fieldTitle}: ${fieldValue}`, 20, yOffset);
          } else {
            doc.text(fieldValue, 20, yOffset);
          }
          yOffset += 15;
        });

        yOffset += 15;

        sections.forEach((section, sectionIndex) => {
          addPageIfNeeded(yOffset);
          doc.setTextColor(0, 0, 0);

          doc.setFontSize(18);
          const sectionTitle = stripHtml(section.title) || "Section Title";
          doc.text(sectionTitle, 20, yOffset);
          yOffset += 15;

          section.fields.forEach((field) => {
            addPageIfNeeded(yOffset);
            doc.setFontSize(14);
            doc.setTextColor(0, 0, 0);

            const fieldTitle = stripHtml(field.title || "");
            const fieldValue = stripHtml(field.value || "No Data");

            if (section.id === "subject-information") {
              const name = stripHtml(field.name || "No Data");
              const address = stripHtml(field.address || "No Data");
              const contact = stripHtml(field.contact || "No Data");
              const legalStructure = stripHtml(
                field.legalStructure || "No Data"
              );
              const registrationNumber = stripHtml(
                field.registrationNumber || "No Data"
              );
              const keyPersons = stripHtml(field.keyPersons || "No Data");

              doc.text(`Name: ${name}`, 20, yOffset);
              doc.text(`Address: ${address}`, 20, yOffset + 7);
              doc.text(`Contact: ${contact}`, 20, yOffset + 14);
              doc.text(`Legal Structure: ${legalStructure}`, 20, yOffset + 21);
              doc.text(
                `Registration Number: ${registrationNumber}`,
                20,
                yOffset + 28
              );
              doc.text(`Key Persons: ${keyPersons}`, 20, yOffset + 35);
              yOffset += 45;
            } else {
              const content = stripHtml(field.content || "No Content");

              if (fieldTitle) {
                doc.text(`${fieldTitle}: ${content}`, 20, yOffset);
              } else {
                doc.text(content, 20, yOffset);
              }

              yOffset += 15;
            }

            addPageIfNeeded(yOffset);
          });

          yOffset += 20;
        });

        doc.save(`${reportName || "report"}.pdf`);
      };
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="oldtemplate-container">
        <header className="oldtemplate-header">
          <img
            src={`${process.env.PUBLIC_URL}/images/athena1.png`}
            alt="Athena Logo"
            className="oldtemplate-image"
          />
          <span className="ishan">ATHENA INTELLIGENCE</span>
        </header>

        <div className="due-diligence-form">
          <div className="heading-container">
            <input
              type="text"
              value={subHeading}
              onChange={handleSubHeadingChange}
              placeholder="Edit template subheading"
              className="main-heading input-centered"
            />
            {errors.subHeading && (
              <span className="error">{errors.subHeading}</span>
            )}
          </div>

          {/* Report Fields Section */}
          <div className="report-fields">
            <h2>Report Fields</h2>
            {reportFields.map((field, index) => (
              <div
                key={index}
                className="report-field-container"
                draggable
                onDragStart={(e) => handleFieldDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleFieldDrop(e, index)}
              >
                <label>{field.title}</label>
                <input
                  type="text"
                  value={field.value}
                  onChange={(e) =>
                    handleReportFieldChange(index, e.target.value)
                  }
                  placeholder={`Enter ${field.title.toLowerCase()}`}
                />
              </div>
            ))}
          </div>

          {/* Sections Management */}
          <div className="sections-container">
            <h2>Sections</h2>
            {sections.map((section) => (
              <div
                key={section.id}
                className="section-container"
                draggable
                onDragStart={() => handleDragStart(section.id)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(section.id)}
              >
                {section.editable ? (
                  <input
                    type="text"
                    value={section.title}
                    onChange={(e) =>
                      setSections((prevSections) =>
                        prevSections.map((s) =>
                          s.id === section.id
                            ? { ...s, title: e.target.value }
                            : s
                        )
                      )
                    }
                    className="section-title-input"
                    placeholder="Enter section title"
                    onBlur={() =>
                      setSections((prevSections) =>
                        prevSections.map((s) =>
                          s.id === section.id ? { ...s, editable: false } : s
                        )
                      )
                    } // Switch back to non-editable mode on blur
                  />
                ) : (
                  <h3
                    onClick={() =>
                      setSections((prevSections) =>
                        prevSections.map((s) =>
                          s.id === section.id ? { ...s, editable: true } : s
                        )
                      )
                    }
                  >
                    {section.title}
                  </h3>
                )}

                {/* Add Field Button */}
                <button
                  onClick={() => handleAddField(section.id)}
                  className="add-field-button"
                >
                  Add Field
                </button>

                {/* Render Fields */}
                {section.fields.map((field, fieldIndex) => (
                  <div key={fieldIndex} className="field-container">
                    {section.id === "subject-information" ? (
                      <>
                        {field.name !== undefined &&
                          renderInputs(
                            "Name",
                            field.name,
                            "name",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Name" // Editable placeholder
                          )}
                        {field.address !== undefined &&
                          renderInputs(
                            "Address",
                            field.address,
                            "address",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Address" // Editable placeholder
                          )}
                        {field.contact !== undefined &&
                          renderInputs(
                            "Contact",
                            field.contact,
                            "contact",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Contact" // Editable placeholder
                          )}
                        {field.legalStructure !== undefined &&
                          renderInputs(
                            "Legal Structure",
                            field.legalStructure,
                            "legalStructure",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Legal Structure" // Editable placeholder
                          )}
                        {field.registrationNumber !== undefined &&
                          renderInputs(
                            "Registration Number",
                            field.registrationNumber,
                            "registrationNumber",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Registration Number" // Editable placeholder
                          )}
                        {field.keyPersons !== undefined &&
                          renderInputs(
                            "Key Persons",
                            field.keyPersons,
                            "keyPersons",
                            section.id,
                            fieldIndex,
                            "Enter",
                            "Enter Key Persons" // Editable placeholder
                          )}
                      </>
                    ) : (
                      <JoditEditor
                        value={field.content}
                        onBlur={(newContent) =>
                          handleChange(
                            section.id,
                            fieldIndex,
                            "content",
                            newContent
                          )
                        }
                        placeholder={`Enter content for ${section.title}`} // Placeholder for JoditEditor
                      />
                    )}

                    {/* Remove Field Button */}
                    <button
                      onClick={() => handleRemoveField(section.id, fieldIndex)}
                      className="remove-field-button"
                    >
                      Remove Field
                    </button>
                  </div>
                ))}

                {/* Remove Section Button */}
                <button
                  onClick={() => handleRemoveSection(section.id)}
                  className="remove-section-button"
                >
                  Remove Section
                </button>
              </div>
            ))}
            <button onClick={handleAddSection} className="add-section-button">
              Add Section
            </button>
          </div>

          {/* Save Button */}
          <div className="saving-container">
            <button
              type="submit"
              className="save-button"
              onClick={handleSaveReport}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Report"}
            </button>
            <button onClick={downloadReport} className="download-button">
              Download DOCX
            </button>
            <button onClick={downloadPDFReport} className="download-button">
              Download PDF
            </button>
          </div>
        </div>

        {/* Modal for Confirming Report Save */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Save</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Project Name</Form.Label>
              <Form.Control type="text" value={project} readonly />
              {errors.name && <span className="error">{errors.name}</span>}
            </Form.Group>
            <Form.Group>
              <Form.Label>Report Name</Form.Label>
              <Form.Control
                type="text"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                placeholder="Enter report name"
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default EditReportById;
