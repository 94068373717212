import React from "react";

const Navbar = () => {
  let Username;

  const role = localStorage.getItem('role');

  if (role === 'admin') {
    Username = localStorage.getItem('adminname');
  } else {
    Username = localStorage.getItem('employeename');
  }

  return (
    <>
      <nav className="sticky-top">
        <div>
          <img className="navy" src={`${process.env.PUBLIC_URL}/images/athena.png`} alt="" />
          <span className="ms-2">ATHENA INTELLIGENCE</span>
        </div>
        <div className="profile-name d-flex align-items-center">
          {/* <img src="images/profile.png" width={32} height={32} alt="" /> */}
          <h6 className="ms-2">{Username}</h6>
          {/* <span className="ms-2">ATHENA INTELLIGENCE</span> */}
          {/* <i className="bi bi-chevron-down"></i> */}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
