import React, { useEffect, useState, useRef } from "react";
import EmployeeSidebar from "./EmployeeSidebar";
import $ from "jquery";
import "datatables.net";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar";
import { useAuth } from '../../AuthContext';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const Projects = () => {
    const dataTableRef = useRef();
    const [projects, setProjects] = useState([]);
    const { apipath } = useAuth();

    const token = localStorage.getItem('accessToken');
    const loggedInUserId = localStorage.getItem('employee_id');

    useEffect(() => {
        // Fetch Projects
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${apipath}/projects/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();

                // Fetch user names for created_by and updated_by
                const projectsWithUserDetails = await Promise.all(data.map(async (project) => {
                    const assignedBy = await fetchUserById(project.assigned_by);
                    return {
                        ...project,
                        assigned_by_name: assignedBy?.name || 'Unknown',
                    };
                }));

                setProjects(projectsWithUserDetails);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        // Fetch user details by ID
        const fetchUserById = async (userId) => {
            try {
                const response = await fetch(`${apipath}/getuserbymainId/${userId}/`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    return await response.json();
                }
            } catch {
                return null;
            }
        };

        fetchProjects();
    }, [apipath, token]);

    const handleMessage = (e) => {
        iziToast.warning({
            title: 'Warning',
            message: 'You are not authorized for this action!',
            position: 'topCenter',
        });
    };

    useEffect(() => {
        if (projects.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [projects]);

    return (
        <>
            <EmployeeSidebar />
            <Navbar />
            <div className="home-main-content">
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Projects assigned to you:</h1>
                    </div>
                    <div className="container-fluid">
                        <div className="row foot-tablerow">
                            <div className="col-lg-12 maintable-column ">
                                <div className="container-fluid mt-4 overflow-auto">
                                    <div>
                                    </div>
                                    <table id="datatable" className="table" ref={dataTableRef}>
                                        <thead>
                                            <tr className="tr1">
                                                <th>ID</th>
                                                <th>Project Name</th>
                                                <th>Report Category</th>
                                                <th>Organisation Name</th>
                                                <th>Created By</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {projects
                                                .filter(project =>
                                                    project.assigned_to_display.includes(loggedInUserId)
                                                )
                                                .map((project, index) => (
                                                <tr key={project.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{project.project_name}</td>
                                                    <td>{project.report_category}</td>
                                                    <td>{project.organisation_name.organisation_name}</td>
                                                    <td>{project.assigned_by_name}</td>
                                                    <td className="td1">
                                                        <i className="fa-regular fa-pen-to-square me-1" onClick={handleMessage}></i>
                                                        <i className="fa-solid fa-trash" onClick={handleMessage}></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Projects;