import React, { useEffect, useState } from "react";
import Sidebar from "./EmployeeSidebar";
import Navbar from "../Navbar";
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useAuth } from '../../AuthContext';

const Reports = () => {
    const [reports, setReports] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const { apipath } = useAuth();

    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        // Fetch all reports from the backend using fetch
        fetch(`${apipath}/reports/`, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        })
            .then(response => response.json())
            .then(data => {
                setReports(data);
            })
            .catch(error => {
                console.error("There was an error fetching the reports!", error);
            });

        // Fetch all templates from the backend using fetch
        fetch(`${apipath}/templates/`, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        })
            .then(response => response.json())
            .then(data => {
                setTemplates(data);
            })
            .catch(error => {
                console.error("There was an error fetching the templates!", error);
            });

    }, [apipath]);

    const handleButtonClick = (reportId) => {
        navigate(`/edit-user-report/${reportId}`);
    };

    const handleCreateReportClick = () => {
        setIsModalOpen(true); // Open the popup
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the popup
    };

    const handleProceedButtonClick = () => {
        if (selectedTemplate) {
            navigate(`/create-user-report/${selectedTemplate}`);
        } else {
            alert("Please select a template");
        }
    };    

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="home-main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="report-content">
                                <div className="report-heading">
                                    <h4>Choose Your Report</h4>
                                    <button onClick={handleCreateReportClick}>
                                        <i className="bi bi-bar-chart me-2"></i>Create New Report
                                    </button>
                                </div>
                                <hr />
                            </div>
                            <div className="row">
                                {reports.map((report) => (
                                    <div className="col-lg-3" key={report.id}>
                                        <div className="template-box">
                                            <img src="images/template.png" alt="Template" />
                                            <div className="report-btn">
                                                <button onClick={() => handleButtonClick(report.id)}>{report.name}</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for creating a new report */}
            <Modal show={isModalOpen} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Template Selection */}
                    <label htmlFor="template">Choose a Template:</label>
                    <select
                        id="template"
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                        className="form-select"
                    >
                        <option value="">Select a template</option>
                        {Array.isArray(templates) && templates.length > 0 ? (
    templates.map((template) => (
      <option key={template.id} value={template.id}>
        {template.name}
      </option>
    ))
  ) : (
    <option disabled>No templates available</option>
  )}
                    </select>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleProceedButtonClick}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Reports;