import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";  // Replaced CKEditor with JoditEditor
import "../../common.css";
import Sidebar from "./AdminSidebar";
import { useAuth } from '../../AuthContext';

function EditTemplateById({ setReportData }) {
  const { id } = useParams(); // Get template ID from URL
  const navigate = useNavigate();

  const { apipath } = useAuth();
  const token = localStorage.getItem('adminaccessToken');

  // State declarations
  const [subHeading, setSubHeading] = useState(""); // Subheading or subject info
  const [errors, setErrors] = useState({});
  const [reportFields, setReportFields] = useState([]); // Report fields like subject info
  const [sections, setSections] = useState([]); // Sections with fields
  const [textInput, setTextInput] = useState(""); // Template name input

// drag and down  new created 

const [dynamicFieldTypes, setDynamicFieldTypes] = useState([]);
const [draggedSectionId, setDraggedSectionId] = useState(null);
const [draggedFieldIndex, setDraggedFieldIndex] = useState(null);

  // Fetch template data by ID when component mounts
  useEffect(() => {
    const fetchTemplateById = async () => {
      try {
        const response = await fetch(`${apipath}/templates/${id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const templateData = await response.json();
          console.log("Fetched template data:", templateData); // Debug log

          // Set template name, subHeading, reportFields, and sections
          setTextInput(templateData.name || "");
          setSubHeading(templateData.subHeading || ""); // Assuming subHeading is for subject info

          // Report fields like subject information are handled here
          setReportFields(templateData.template_structure.reportFields || []);

          // Sections with fields
          setSections(templateData.template_structure.sections || []);
        } else {
          console.error("Failed to fetch template data.");
        }
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchTemplateById();
  }, [apipath, token, id]);

  // Submit updated template data to backend
  const submitUpdatedTemplateData = async (templateData) => {
    try {
      const response = await fetch(`${apipath}/templates/${id}/`, {
        method: "PUT", // Use PUT for update
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(templateData),
      });

      if (response.ok) {
        console.log("Template updated successfully!");
        navigate("/reporttemplates");
      } else {
        const errorData = await response.json();
        console.error("Error updating template:", errorData);
        setErrors(errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle submit button for updating template
  const handleSaveTemplate = () => {
    const templateData = {
      name: textInput, // Template name from the input
      subHeading, // Subheading or subject information
      template_structure: {
        reportFields, // Report fields
        sections, // Sections and fields
      },
    };
    console.log("Updated Template Data:", templateData);

    // Send updated data to the backend
    submitUpdatedTemplateData(templateData);
  };

  const handleSubHeadingChange = (e) => {
    setSubHeading(e.target.value);
  };

  const handleReportFieldChange = (index, value) => {
    const updatedFields = [...reportFields];
    updatedFields[index].value = value;
    setReportFields(updatedFields);
  };

  const handleChange = (sectionId, fieldIndex, fieldKey, value) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = [...section.fields];
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          [fieldKey]: value,
        };
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

// Add new section
const handleAddSection = () => {
  const newSection = {
    id: `section-${sections.length + 1}`,
    title: `New Section ${sections.length + 1}`,
    fields: [
      {
        id: `field-1`,
        content: "",
      },
    ],
    editable: true,  // Mark the new section title as editable by default
  };
  setSections([...sections, newSection]);
};

// Dynamic fields management addition
const addDynamicFieldType = () => {
  setDynamicFieldTypes([...dynamicFieldTypes, { id: Date.now(), name: "" }]);
};

  // Drag-and-drop handlers for sections
  const handleDragStart = (sectionId) => {
    setDraggedSectionId(sectionId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (sectionId) => {
    const draggedIndex = sections.findIndex(
      (section) => section.id === draggedSectionId
    );
    const targetIndex = sections.findIndex(
      (section) => section.id === sectionId
    );
    const updatedSections = [...sections];
    const [draggedSection] = updatedSections.splice(draggedIndex, 1);
    updatedSections.splice(targetIndex, 0, draggedSection);
    setSections(updatedSections);
    setDraggedSectionId(null);
  };

  // Drag-and-drop handlers for report fields
  const handleFieldDragStart = (e, index) => {
    setDraggedFieldIndex(index);
  };

  const handleFieldDrop = (e, index) => {
    e.preventDefault();
    if (draggedFieldIndex !== null && draggedFieldIndex !== index) {
      const updatedFields = [...reportFields];
      const [draggedField] = updatedFields.splice(draggedFieldIndex, 1);
      updatedFields.splice(index, 0, draggedField);
      setReportFields(updatedFields);
      setDraggedFieldIndex(null);
    }
  };
  
  // Remove section
  const handleRemoveSection = (sectionId) => {
    const updatedSections = sections.filter(section => section.id !== sectionId);
    setSections(updatedSections);
  };

  // Add new field to a section
  const handleAddField = (sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = [
          ...section.fields,
          { id: `field-${section.fields.length + 1}`, content: "" },
        ];
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  // Remove field from a section
  const handleRemoveField = (sectionId, fieldIndex) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedFields = section.fields.filter((_, index) => index !== fieldIndex);
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };
  const renderInputs = (
    label,
    value,
    fieldKey,
    sectionId,
    fieldIndex,
    placeholderPrefix,
    editablePlaceholder // New parameter for editable placeholder
  ) => (
    <div className="input-group">
      <label>{label}</label>
      <input
        type="text"
        value={Array.isArray(value) ? value.join(", ") : value} // Join if it's an array
        onChange={(e) =>
          handleChange(sectionId, fieldIndex, fieldKey, e.target.value)
        }
        placeholder={`${placeholderPrefix} ${editablePlaceholder || label.toLowerCase()}`} // Use editable placeholder
      />
    </div>
  );
  
  return (
    <>
    <Sidebar />
    <div className="oldtemplate-container">
      <header className="oldtemplate-header">
        <img
          src={`${process.env.PUBLIC_URL}/images/athena1.png`}
          alt="Athena Logo"
          className="oldtemplate-image"
        />
        <span className="ishan">ATHENA INTELLIGENCE</span>
      </header>
  
      <div className="due-diligence-form">
        <div className="heading-container">
          <input
            type="text"
            value={subHeading}
            onChange={handleSubHeadingChange}
            placeholder="Edit template subheading"
            className="main-heading input-centered"
          />
          {errors.subHeading && <span className="error">{errors.subHeading}</span>}
        </div>
  
        {/* Report Fields Section */}
        <div className="report-fields">
          <h2>Report Fields</h2>
          {reportFields.map((field, index) => (
            <div key={index} className="report-field-container">
              <label>{field.title}</label>
              <input
                type="text"
                value={field.value}
                onChange={(e) => handleReportFieldChange(index, e.target.value)}
                placeholder={`Enter ${field.title.toLowerCase()}`}

                // extra added
                draggable
                onDragStart={(e) => handleFieldDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleFieldDrop(e, index)}
              />
            </div>
          ))}
        </div>
  
        {/* Sections Management */}
        <div className="sections-container">
          <h2>Sections</h2>
          {sections.map((section) => (
            <div key={section.id} className="section-container"
                    // extra added
                    draggable
                    onDragStart={() => handleDragStart(section.id)}
                    onDragOver={handleDragOver}
                    onDrop={() => handleDrop(section.id)}>
              {section.editable ? (
                <input
                  type="text"
                  value={section.title}
                  onChange={(e) =>
                    setSections((prevSections) =>
                      prevSections.map((s) =>
                        s.id === section.id
                          ? { ...s, title: e.target.value }
                          : s
                      )
                    )
                  }
                  className="section-title-input"
                  placeholder="Enter section title"
                  onBlur={() =>
                    setSections((prevSections) =>
                      prevSections.map((s) =>
                        s.id === section.id
                          ? { ...s, editable: false }
                          : s
                      )
                    )
                  } // Switch back to non-editable mode on blur
                />
              ) : (
                <h3
                  onClick={() =>
                    setSections((prevSections) =>
                      prevSections.map((s) =>
                        s.id === section.id
                          ? { ...s, editable: true }
                          : s
                      )
                    )
                  }
                >
                  {section.title}
                </h3>
              )}
  
              {/* Add Field Button */}
              <button onClick={() => handleAddField(section.id)} className="add-field-button">
                Add Field
              </button>
  
              {/* Render Fields */}
              {section.fields.map((field, fieldIndex) => (
                <div key={fieldIndex} className="field-container">
                  {section.id === "subject-information" ? (
                    <>
                      {field.name !== undefined &&
                        renderInputs(
                          "Name",
                          field.name,
                          "name",
                          section.id,
                          fieldIndex,
                          "Enter",
                          "Enter Name" // Editable placeholder
                        )}
                      {field.address !== undefined &&
                        renderInputs(
                          "Address",
                          field.address,
                          "address",
                          section.id,
                          fieldIndex,
                          "Enter",
                          "Enter Address" // Editable placeholder
                        )}
                      {field.contact !== undefined &&
                        renderInputs(
                          "Contact",
                          field.contact,
                          "contact",
                          section.id,
                          fieldIndex,
                          "Enter",
                          "Enter Contact" // Editable placeholder
                        )}
                      {field.legalStructure !== undefined &&
                        renderInputs(
                          "Legal Structure",
                          field.legalStructure,
                          "legalStructure",
                          section.id,
                          fieldIndex,
                          "Enter",
                          "Enter Legal Structure" // Editable placeholder
                        )}
                      {field.registrationNumber !== undefined &&
                        renderInputs(
                          "Registration Number",
                          field.registrationNumber,
                          "registrationNumber",
                          section.id,
                          fieldIndex,
                          "Enter",
                          "Enter Registration Number" // Editable placeholder
                        )}
                      {field.keyPersons !== undefined &&
                        renderInputs(
                          "Key Persons",
                          field.keyPersons,
                          "keyPersons",
                          section.id,
                          fieldIndex,
                          "Enter",
                          "Enter Key Persons" // Editable placeholder
                        )}
                    </>
                  ) : (
                    <JoditEditor
                      value={field.content}
                      onBlur={(newContent) =>
                        handleChange(section.id, fieldIndex, "content", newContent)
                      }
                      placeholder={`Enter content for ${section.title}`} // Placeholder for JoditEditor
                    />
                  )}
  
                  {/* Remove Field Button */}
                  <button
                    onClick={() => handleRemoveField(section.id, fieldIndex)}
                    className="remove-field-button"
                  >
                    Remove Field
                  </button>
                </div>
              ))}
  
              {/* Remove Section Button */}
              <button onClick={() => handleRemoveSection(section.id)} className="remove-section-button">
                Remove Section
              </button>
            </div>
          ))}
  
          {/* Add Section Button */}
          <button onClick={handleAddSection} className="add-section-button">
            Add Section
          </button>
        </div>
  
        {/* Save Template Button */}
        <div className="submit-container">
          <button type="submit" onClick={handleSaveTemplate} className="submit-button">
            Update
          </button>
        </div>
      </div>
      <footer className="oldtemplate-footer">
        Strictly Private & Confidential
      </footer>
    </div>
  </>
  
  
  );
}

export default EditTemplateById;