import React from 'react';
import CreateNewTemplate from './CreateNewTemplate';
import "../../common.css";

const CreateTemplate = ({ setReportData }) => {
  return (
    <div className="oldtemplate-container">
      {/* Header section with image and text */}
      <header className="oldtemplate-header">
        <img
       src={`${process.env.PUBLIC_URL}/images/athena1.png`} // Path to the image in public folder
          alt="Athena Logo"
          className="oldtemplate-image"
        />
        <span className='ishan'>ATHENA INTELLIGENCE</span>
      </header> 

      {/* Due Diligence Form */}
      <CreateNewTemplate setReportData={setReportData} />

      {/* Footer */}
      <footer className="oldtemplate-footer">
        Strictly Private & Confidential
      </footer>
    </div>
  );
};

export default CreateTemplate;
