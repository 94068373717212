import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./AdminSidebar";
import $ from "jquery";
import "datatables.net";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar";
import { Modal } from "react-bootstrap";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import { useAuth } from '../../AuthContext';

const Organisation = () => {
    const dataTableRef = useRef();
    const [organisations, setOrganisations] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [currentOrganisation, setCurrentOrganisation] = useState(null);
    const { apipath } = useAuth();
    const [organisationData, setOrganisationData] = useState({
        organisation_name: "",
    });

    const token = localStorage.getItem('adminaccessToken');

    useEffect(() => {
        // Fetch organisations
        const fetchOrganisations = async () => {
            try {
                const response = await fetch(`${apipath}/organisation/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                setOrganisations(data);
            } catch (error) {
                console.error("Error fetching organisations:", error);
            }
        };

        fetchOrganisations();
    }, [apipath, token]);

    const handleShowEdit = (organisation) => {
        setCurrentOrganisation(organisation);
        setOrganisationData({
            organisation_name: organisation.organisation_name,
        });
        setShowEdit(true);
    };

    const handleCloseEdit = () => {
        setShowEdit(false);
        setCurrentOrganisation(null);
    };

    const handleShowDelete = (organisation) => {
        setCurrentOrganisation(organisation);
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setCurrentOrganisation(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrganisationData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apipath}/organisation/`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organisation_name: organisationData.organisation_name,
                })
            });
            if (!response.ok) {
                const errorData = await response.json();
                iziToast.error({
                    title: 'Error',
                    message: errorData.message || 'Error occurred while creating project',
                    position: 'topCenter',
                });
                return;
            }
            iziToast.success({
                title: 'Success',
                message: 'Project added successfully',
                position: 'topCenter',
            });

            const updatedResponse = await fetch(`${apipath}/organisation/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedData = await updatedResponse.json();
            setOrganisations(updatedData);

            const registerModal = new window.bootstrap.Modal(document.getElementById('registerModal'));
            registerModal.hide();
            setOrganisationData({
                organisation_name: "",
            });
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while creating project.',
                position: 'topCenter',
            });
            console.error("Error creating project:", error);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apipath}/organisation/${currentOrganisation.id}/`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organisation_name: organisationData.organisation_name,
                })
            });
            if (!response.ok) {
                const errorData = await response.json();
                iziToast.error({
                    title: 'Error',
                    message: errorData.message || 'Error occurred while updating project',
                    position: 'topCenter',
                });
                return;
            }
            iziToast.success({
                title: 'Success',
                message: 'Project updated successfully',
                position: 'topCenter',
            });

            const updatedResponse = await fetch(`${apipath}/organisation/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedData = await updatedResponse.json();
            setOrganisations(updatedData);

            setShowEdit(false);
            setCurrentOrganisation(null);
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while updating project.',
                position: 'topCenter',
            });
            console.error("Error updating project:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`${apipath}/organisation/${currentOrganisation.id}/`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                iziToast.error({
                    title: 'Error',
                    message: errorData.message || 'Error occurred while deleting project',
                    position: 'topCenter',
                });
                return;
            }
            iziToast.success({
                title: 'Success',
                message: 'Project deleted successfully',
                position: 'topCenter',
            });

            const updatedResponse = await fetch(`${apipath}/organisation/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedData = await updatedResponse.json();
            setOrganisations(updatedData);

            setShowDelete(false);
            setCurrentOrganisation(null);
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while deleting project.',
                position: 'topCenter',
            });
            console.error("Error deleting project:", error);
        }
    };

    useEffect(() => {
        if (organisations.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [organisations]);

    return (
        <>
            <Sidebar />
            <Navbar />
            <div className="home-main-content">
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Organisation Management</h1>
                    </div>
                    <div className="container-fluid">
                        <div className="row foot-tablerow">
                            <div className="col-lg-12 maintable-column ">
                                <div className="container-fluid mt-4 overflow-auto">
                                    <div className="addemployee">
                                        <button className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#registerModal">
                                            <i className="fa-solid fa-user-plus"></i> Add Organisation
                                        </button>
                                    </div>
                                    <table id="datatable" className="table" ref={dataTableRef}>
                                        <thead>
                                            <tr className="tr1">
                                                <th>S. No.</th>
                                                <th>Organisation Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {organisations.map((organisation, index) => (
                                                <tr key={organisation.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{organisation.organisation_name}</td>
                                                    <td className="td1">
                                                        <i className="fa-regular fa-pen-to-square me-1" onClick={() => handleShowEdit(organisation)}></i>
                                                        <i className="fa-solid fa-trash" onClick={() => handleShowDelete(organisation)}></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Add Organisation Modal */}
                    <div className="modal fade" id="registerModal" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="registerModalLabel">Add Organisation</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Organisation Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="organisation_name"
                                                // value={organisationData.organisation_name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 text-center">
                                            <button type="submit" className="btn btn-dark">Add Organisation</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Edit Project Modal */}
                    <Modal show={showEdit} onHide={handleCloseEdit} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Project</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleUpdate}>
                                <div className="mb-3">
                                    <label className="form-label">Project Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="organisation_name"
                                        value={organisationData.organisation_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3 text-center">
                                    <button type="submit" className="btn btn-dark">Update Project</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>

                    {/* Delete Project Modal */}
                    <Modal show={showDelete} onHide={handleCloseDelete} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Organisation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete this Organisation <strong>{currentOrganisation?.organisation_name}</strong>?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCloseDelete}>Cancel</button>
                            <button className="btn btn-danger" onClick={handleDelete}>Delete</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default Organisation;