import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from '../../AuthContext';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent click from bubbling up
    setIsDropdownOpen(!isDropdownOpen);
  };
  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  const token = localStorage.getItem('adminaccessToken');

  const { adminLogout } = useAuth();
  if (!token) {
    adminLogout();
    window.location.href = "/";
  }

  return (
    <>
      <header>
        <div className="toggle" onClick={toggleSidebar}>
          <i className={isSidebarOpen ? "bi bi-x" : "bi bi-list"}></i>
        </div>
      </header>
      <section className={`dapp-side-bar-nav ${isSidebarOpen ? 'open' : ''}`}>
        <ul className="pt-4">
          {/* <div className="nav-menu d-flex align-items-center mx-3 mb-4">
            <Link to="/"> 
              <img src="images/logo.png" alt="Logo" />  
            </Link>
          </div> */}
          <div className='hr-class'>
            <hr className="colored-hr" />
          </div>
          <small>Main</small>
          <li className={isActiveLink("/dashboard") ? "active" : ""}>
            <Link to="/dashboard">
              <span className="icon"><i className="bi bi-columns-gap"></i></span>
              <span className="title">Dashboard</span>
            </Link>
          </li>

          <li className="admin-sidebar-container5" onClick={toggleDropdown} >
            <div className="admin-sidebar-container">
              <span className="icon"><i className="bi bi-kanban"></i></span>
              <span className="title" style={{ marginLeft: '20px' }}>Project Master Data</span>
              <i className={`bi ${isDropdownOpen ? 'bi-chevron-up' : 'bi-chevron-down'}`}style={{ marginLeft: '5px', marginBottom: '5px' }}></i>
            </div>
            {isDropdownOpen && (
              <ul className="admin-sidebar-container1">
                <li className={isActiveLink("/organisation-list") ? "active" : ""}>
                  <Link to="/organisation-list" className="admin-sidebar-container2 ">
                    <span className="icon"></span>
                    <span className="title">Organisation</span>
                  </Link>
                </li>
                <li className={isActiveLink("/admin-project") ? "active" : ""}>
                  <Link to="/admin-project" className="admin-sidebar-container3">
                    <span className="icon"></span>
                    <span className="title">My Projects</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className={isActiveLink("/admin-document") ? "active" : ""}>
            <Link to="/admin-document">
              <span className="icon"><i class="bi bi-file-earmark-medical"></i></span>
              <span className="title">Report Archive</span>
            </Link>
          </li>
          <li className={isActiveLink("/admin-reports") ? "active" : ""}>
            <Link to="/admin-reports">
              <span className="icon"><i class="bi bi-file-earmark-medical"></i></span>
              <span className="title">Main Reports</span>
            </Link>
          </li>
          <li className={isActiveLink("/reporttemplates") ? "active" : ""}>
            <Link to="/reporttemplates">
              <span className="icon"><i class="bi bi-bar-chart"></i></span>
              <span className="title">Report Templates</span>
            </Link>
          </li>
          <div className='hr-class'>
            <hr className="colored-hr" />
          </div>
          {/* <li>
            <Link to="">
              <span className="icon"><i class="bi bi-question-circle"></i></span>
              <span className="title">Help</span>
            </Link>
          </li> */}
          <li>
            <Link onClick={adminLogout}>
              <span className="icon"><i class="bi bi-door-closed"></i></span>
              <span className="title" >Logout</span>
            </Link>
          </li>
        </ul>
      </section>

    </>
  );
};

export default Sidebar;
