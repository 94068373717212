import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import Sidebar from "./AdminSidebar";
import Navbar from "../Navbar";
import { useAuth } from '../../AuthContext';

const ProjectDetails = () => {
    const { id } = useParams();
    const token = localStorage.getItem('adminaccessToken');
    const [project, setProject] = useState(null);
    const [reports, setReports] = useState([]);
    const [assignedEmployees, setAssignedEmployees] = useState([]);
    const { apipath } = useAuth();
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch project details
        const fetchProject = async () => {
            try {
                const response = await fetch(`${apipath}/project/${id}/`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch project details');
                }

                const data = await response.json();
                setProject(data);

                // Fetch assigned_by user details to get the name
                const assignedByUser = await fetchUserById(data.assigned_by);
                const assigned_by_name = assignedByUser?.name || 'Unknown';

                // Update project state with assigned_by_name
                setProject({ ...data, assigned_by_name });

                // Fetch assigned employees
                const assignedTo = data.assigned_to_display || [];
                fetchAssignedEmployees(assignedTo);

            } catch (error) {
                setError(error);
            }
        };

        // Fetch all reports and get user details for created_by and updated_by
        const fetchReports = async () => {
            try {
                const response = await fetch(`${apipath}/reports/`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch reports');
                }

                const data = await response.json();

                // Fetch user names for created_by and updated_by
                const reportsWithUserDetails = await Promise.all(data.map(async (report) => {
                    const createdBy = await fetchUserById(report.created_by);
                    const updatedBy = await fetchUserById(report.updated_by);
                    return {
                        ...report,
                        created_by_name: createdBy?.name || 'Unknown',
                        updated_by_name: updatedBy?.name || 'Unknown',
                    };
                }));

                setReports(reportsWithUserDetails);
            } catch (error) {
                setError(error);
            }
        };

        // Fetch assigned employees' details
        const fetchAssignedEmployees = async (assignedTo) => {
            try {
                const employeeRequests = assignedTo.map(empId =>
                    fetch(`${apipath}/getuserbyId/${empId}/`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }).then(res => res.json())
                );

                const employees = await Promise.all(employeeRequests);
                setAssignedEmployees(employees);
            } catch (error) {
                setError(error);
            }
        };

        // Fetch user details by ID
        const fetchUserById = async (userId) => {
            try {
                const response = await fetch(`${apipath}/getuserbymainId/${userId}/`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    return await response.json();
                }
            } catch {
                return null;
            }
        };

        fetchProject();
        fetchReports();
    }, [apipath, id, token]);

    // Helper function to format report status
    const formatReportStatus = (status) => {
        switch (status) {
            case "ongoing":
                return "Editing Mode";
            case "under_review":
                return "Review Mode";
            case "published":
                return "Published Mode";
            default:
                return status;
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    if (error) return <p>Error: {error.message}</p>;

    const associatedReports = reports.filter(report => report.project === parseInt(id));

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="home-main-content">
                <div className="container pt-4">
                    <h3>Project Detailed View</h3>
                    <div className="row">
                        <div className="col-12">
                            <hr />
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="project-detail-tab" data-bs-toggle="tab" data-bs-target="#project-detail" type="button" role="tab" aria-controls="ongoing-reports" aria-selected="true">
                                        <i className="bi bi-info-circle-fill me-2"></i> Project Details
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="associated-reports-tab" data-bs-toggle="tab" data-bs-target="#associated-reports" type="button" role="tab" aria-controls="under-verification" aria-selected="false">
                                        <i className="bi bi-journal-text me-2"></i> Associated Reports
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="assigned-employee-tab" data-bs-toggle="tab" data-bs-target="#assigned-employee" type="button" role="tab" aria-controls="closed-reports" aria-selected="false">
                                        <i className="bi bi-people-fill me-2"></i> Assigned Employee
                                    </button>
                                </li>
                            </ul>
                            <hr />
                        </div>
                    </div>

                    <div className="tab-content" id="myTabContent">
                        {/* Project Details */}
                        <div className="tab-pane fade show active" id="project-detail" role="tabpanel" aria-labelledby="project-detail-tab">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    {project ? (
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Project Name</th>
                                                        <td>{project.project_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Organisation</th>
                                                        <td>{project.organisation_name.organisation_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Report Category</th>
                                                        <td>{project.report_category}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Project Created By</th>
                                                        <td>{project.assigned_by_name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <p className="mt-2">Loading project details...</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Associated Reports */}
                        <div className="tab-pane fade" id="associated-reports" role="tabpanel" aria-labelledby="associated-reports-tab">
                            <h4>Associated Reports</h4>
                            {associatedReports.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Report Title</th>
                                            <th scope="col">Report Status</th>
                                            <th scope="col">Created On</th>
                                            <th scope="col">Created By</th>
                                            <th scope="col">Last Updated On</th>
                                            <th scope="col">Last Updated By</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {associatedReports.map((report, index) => (
                                            <tr key={report.id}>
                                                <td>{index + 1}</td>
                                                <td>{report.name}</td>
                                                <td>{formatReportStatus(report.report_status)}</td>
                                                <td>{formatDate(report.created_at)}</td>
                                                <td>{report.created_by_name}</td>
                                                <td>{formatDate(report.updated_at)}</td>
                                                <td>{report.updated_by_name}</td>
                                                <td><Link to={`/edit-admin-report/${report.id}`}>View Report</Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No associated reports found for this project.</p>
                            )}
                        </div>

                        {/* Assigned Employee */}
                        <div className="tab-pane fade" id="assigned-employee" role="tabpanel" aria-labelledby="assigned-employee-tab">
                            <h4>Assigned Employees</h4>
                            {assignedEmployees.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Employee ID</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Employee Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {assignedEmployees.map((employee, index) => (
                                            <tr key={employee.id}>
                                                <td>{index + 1}</td>
                                                <td>{employee.id}</td>
                                                <td>{employee.name}</td>
                                                <td>{employee.email}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No employees assigned to this project.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectDetails;
